import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";

import AsyncSelect from "react-select/async";
import {  useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import partnersPartner from "../../../store/apis/partnersApis";
import PrestationService from "../../../store/apis/PrestationApi";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object({});

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const AddNewPartnerForm = ({
  dialog,
  onClose,
  demande,
  partner,
  setPartner,
  setLoadPresta,
}) => {
  const [selected, setSelected] = useState(
    partner
      ? {
          value: partner.id,
          label: partner.nom + " " + partner.prenom,
          id: partner.id,
          libelle: partner.nom + " " + partner.prenom,
          description:
            "Email : " +
            partner.email +
            "<br /> Telephone : " +
            partner.telephone,
        }
      : null
  );
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [partners, setPartners] = useState({});
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    setLoading(true);
    let newData = {
      prestataire: `/api/users/${selected.id}`,
    };
    /* console.log(newData, demande)
    return */
    PrestationService.editPrestation(demande.id, newData)
      .then((res) => {
        setPartner(res.prestataire);
        setLoading(false);
        setLoadPresta();
        onClose();
      })
      .catch((error) => console.log(error));
    setSelected();
  };

  useEffect(() => {
    const listPartners = [];
    partnersPartner
      .listPartners(1)
      .then((res) => {
        for (let i = 0; i < res.length; i++) {
          const El = res[i];
          listPartners.push({
            value: El.id,
            label: El.nom + " " + El.prenom,
            id: El.id,
            libelle: El.nom + " " + El.prenom,
            description:
              "Email : " + El.email + "<br /> Telephone : " + El.telephone,
          });
        }
        setPartners(listPartners);
        setLoad(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChangePartner = (newPartner) => {
    setSelected(newPartner);
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      const listPartners = [];
      partnersPartner
        .listPartners(1, inputValue)
        .then((res) => {
          const options = res.map((item) => ({
            value: item.id,
            label: item.nom + " " + item.prenom,
            id: item.id,
            libelle: item.nom + " " + item.prenom,
            description:
              "Email : " + item.email + "<br /> Telephone : " + item.telephone,
          }));
          callback(options);
        })
        .catch((error) => console.log(error));
    }, 1000); // Simulate 1 second delay
  };

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minHeight: 600, minWidth: 600, maxWidth: { md: 600, lg: 600 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Attribuer un partenaire
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItem={"center"}
            justifyContent={"space-between"}
            rowGap={2}
            flexWrap={"wrap"}
          >
            <Box flexBasis={{ xs: "100%", md: "100%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Selectionner le partenaire
                </Typography>
                <Box>
                  {/* <Controller
                    name="service"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="service-select-label">Select Partner</InputLabel>
                        <Select
                          {...field}
                          labelId="service-select-label"
                          value={selected}
                          onChange={handleChangePartner}
                        >
                          {categories.map((category) => (
                            <optgroup label={category.name} key={category.id}>
                              {category.options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </optgroup>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  /> */}
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    styles={customStyles}
                    loadOptions={loadOptions}
                    onChange={handleChangePartner}
                    placeholder="Chercher le partenaire ici."
                  />
                  {/* {load ? (
                    <CircularProgress />
                  ) : (
                    <Controller
                      name={"service"}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={selected}
                          styles={customStyles}
                          placeholder={
                            "Saisissez le nom/prenom du partenaire ici"
                          }
                          options={partners}
                          onChange={handleChangePartner}
                        />
                      )}
                    />
                  )} */}
                </Box>
              </Box>
            </Box>

            <Box flex={{ xs: "0 1 100%", md: "0 1 100%" }}> </Box>
            {selected && (
              <Box>
                <Box>
                  <Typography variant="caption" component={"label"}>
                    Contact du partenaire
                  </Typography>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                    fontSize={18}
                    lineHeight={"26px"}
                  >
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: selected.description }}
                    ></div>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </form>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton type={"red"} handleClick={onClose}>
                Annuler
              </CustomButton>
              {selected && (
                <CustomButton
                  type={"yellow"}
                  textColor={"white"}
                  handleClick={handleSubmit(onSubmit)}
                >
                  Attribuer
                </CustomButton>
              )}
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default AddNewPartnerForm;
