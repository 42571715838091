import {
  Box,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import React, { forwardRef, useState } from "react";
import { ScrollView } from "react-native-web";
import styles from "../../assets/css/style";
import {  SuperAdminNavBar } from "../../components";
import TabPanel from "../../components/TabPanel";
import Admins from "./Admins";
import Benefits from "./Benefits";
import Customer from "./Customers";
import Documents from "./Documents";
import Partners from "./Partners";
import bgimg from "../../assets/images/black-bg-dots.png";
import CallsCalendar from "./CallsCalendar/CallsCalendar";
import Params from "./Params";
import Setting from "../customer/home/sections/SettingSection";
import { StyleSheet } from "react-native";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuperAdminLayout() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [activePage, setActivePage] = useState("dashboard");
  const items = [
    {
      label: "Prestations",
      content: <Benefits />,
    },
    {
      label: "Partenaires",
      content: <Partners />,
    },
    {
      label: "clients",
      content: <Customer />,
    },
    {
      label: "Admins",
      content: <Admins />,
    },
    /* {
      label: "créer un devis",
      content: <Quotes />,
    }, */
    {
      label: "documents",
      content: <Documents />,
    },
    {
      label: "Paramètres",
      content: <Params />,
    },
  ];

  const [dialog, setDialog] = useState(false);
  const handleDialog = () => {
    //if (dialog) dispatch(reset());
    setDialog(!dialog);
  };

  return (
    <ScrollView
      style={{
        backgroundImage: "url(" + bgimg + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <SuperAdminNavBar
        callendarClick={() => setActivePage("calls")}
        handleDialog={handleDialog}
      />

      <Box style={StyleSheet.flatten(styles.bgWrap)}>
        <Typography
          color={"#fff"}
          style={StyleSheet.flatten(styles.dottedLine)}
          align="center"
          variant="h3"
        >
          {(() => {
            switch (activePage) {
              case "dashboard":
                return "Dashboard";

              case "calls":
                return "Calendrier";
            }
          })()}
        </Typography>
        <Container maxWidth="lg">
          {(() => {
            switch (activePage) {
              case "dashboard":
                return <TabPanel type="style2" items={items} />;

              case "calls":
                return <CallsCalendar setActivePage={setActivePage} />;
            }
          })()}
        </Container>
      </Box>
      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        fullScreen={!isMd}
        PaperProps={{
          sx: {
            maxWidth: { md: 670 },
            width: { md: 670 },
            borderRadius: { md: 4 },
            boxShadow: "none",
          },
        }}
        onClose={() => {
          dispatch(reset());
          setDialog(!dialog);
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: theme.palette.background.backdropModal,
          },
        }}
      >
        <Setting handleDialog={handleDialog} />
      </Dialog>
    </ScrollView>
  );
}
