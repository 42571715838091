import {
  Box,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
} from "@mui/material";
import { Edit, KeyboardBackspaceOutlined, Refresh } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import ring from "../../../assets/images/ring.png";
import moment from "moment";
import {
  document_type_bon_intervention,
  document_type_devis,
  document_type_facture,
  document_type_fiche_intervention,
  document_type_order_travail,
  rdv_type_call,
  rdv_type_prestation,
} from "../../../config/keys";
import { TextAreaInput } from "../../../components/inputs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PrestationService from "../../../store/apis/PrestationApi";
import AddNewPartnerForm from "./NewPartner";
import PlanificationForm from "./Planification";
import EditRdvForm from "./EditRdv";
import ImagesList from "./Images";
import EditStatusForm from "./EditStatus";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    //margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 16,
    },
    "&:first-of-type": {
      borderRadius: 16,
    },
  },
}));

const schema = yup.object({});

export default function DetailsDemande(props) {
  const [loading, setLoading] = useState(true);
  const [presta, setPresta] = useState();
  const [alignment, setAlignment] = useState();
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [editDetail, setEditDetail] = useState(false);
  const [editReference, setEditReference] = useState(false);
  const [editRdv, setEditRdv] = useState(false);
  const [showPlanification, setShowPlanification] = useState(false);
  const [showAddPartenaire, setShowAddPartenaire] = useState(false);
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [partner, setPartner] = useState();
  const [datePlanification, setDatePlanification] = useState();
  const [dateAppel, setDateAppel] = useState();
  const [detailDevis, setDetailDevis] = useState();
  const [detailDocument, setDetailDocument] = useState();
  const [showImages, setShowImages] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (Object.keys(props.detailPresta).length > 0) {
      setPresta(props.detailPresta);

      /* console.log(props.detailPresta?.client?.calendar[0]?.date_debut); */
      setAlignment(props.detailPresta.client?.type_client);
      setPartner(props.detailPresta.prestataire);
      setDateAppel(
        props.detailPresta.client?.calendar
          ?.filter((rdv) => rdv.type === rdv_type_call)
          .pop()
      );
      setDatePlanification(
        props.detailPresta.client?.calendar
          ?.filter((rdv) => rdv.type === rdv_type_prestation)
          .pop()
      );
      setValue("note", props.detailPresta.note);
      setValue("detail", props.detailPresta.detail);
      setValue("reference", props.detailPresta.reference);
      setLoading(false);
    } else {
      fetchData(props.presta);
    }
  }, [props.presta]);

  const fetchData = (data) => {
    setLoading(true);

    PrestationService.getPrestation(data.id)
      .then((response) => {
        props.setDetailPresta(response);
        setPresta(response);
        setAlignment(response.client?.type_client);
        setPartner(response.prestataire);
        setDateAppel(
          response.client?.calendar
            ?.filter((rdv) => rdv.type === rdv_type_call)
            .pop()
        );
        setDetailDevis(
          response.documents.find(
            (obj) => obj.type.toLowerCase() === document_type_devis
          )
            ? response.documents.find(
                (obj) => obj.type.toLowerCase() === document_type_devis
              )
            : null
        );
        setDetailDocument(
          response.documents.find(
            (obj) => obj.type.toLowerCase() === document_type_fiche_intervention
          )
            ? response.documents.find(
                (obj) =>
                  obj.type.toLowerCase() === document_type_fiche_intervention
              )
            : null
        );
        setDatePlanification(
          response.client?.calendar
            ?.filter((rdv) => rdv.type === rdv_type_prestation)
            .pop()
        );
        setValue("note", response.note);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const HandleNote = (data) => {
    setLoad(true);

    PrestationService.editPrestation(presta.id, { note: data.note })
      .then((response) => {
        setLoad(false);
        presta.note = data.note;
        setEditNote(false);
      })
      .catch((err) => console.log(err));
  };

  const HandleReference = (data) => {
    setLoad(true);

    PrestationService.editPrestation(presta.id, { reference: data.reference })
      .then((response) => {
        setLoad(false);
        presta.reference = data.reference;
        setEditReference(false);
      })
      .catch((err) => console.log(err));
  };

  const HandleDetail = (data) => {
    setLoad(true);

    PrestationService.editPrestation(presta.id, { detail: data.detail })
      .then((response) => {
        setLoad(false);
        presta.detail = data.detail;
        setEditDetail(false);
      })
      .catch((err) => console.log(err));
  };

  const refresh = () => {
    fetchData(props.presta);
  };

  if (loading)
    return (
      <Box
        height={"30vh"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box>
        <Typography fontSize={{ xs: 16 }} color="red">
          Something Went Wrong
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ color: "#fff" }}>
      <Button
        startIcon={<KeyboardBackspaceOutlined />}
        variant={"text"}
        color={"primary"}
        sx={{ fontWeight: 700, textTransform: "capitalize" }}
        onClick={props.goBack}
      >
        Retour
      </Button>
      <Button
        startIcon={<Refresh />}
        variant={"text"}
        color={"primary"}
        sx={{
          fontWeight: 700,
          textTransform: "capitalize",
          float: "right",
          marginTop: "13px",
        }}
        onClick={refresh}
      >
        Rafraîchir
      </Button>

      {presta && (
        <>
          <Typography
            variant={"body2"}
            fontWeight={"700"}
            textTransform={"uppercase"}
            fontSize={{ md: 24 }}
            mt={2}
            mb={2}
          >
            Detail client
          </Typography>
          <Box
            display={"flex"}
            alignItems={{ xs: "center", lg: "start" }}
            justifyContent={{ xs: "center", lg: "space-between" }}
            flexDirection={{ xs: "column", lg: "row" }}
            gap={{ xs: 3 }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContentt={"center"}
              flexDirection={"column"}
              gap={{ xs: 3, md: 6 }}
            >
              <Box
                display={"flex"}
                alignItems={{ xs: "center", md: "start" }}
                justifyContent={{ xs: "center", md: "start" }}
                gap={{ xs: 3, md: 6, lg: 1 }}
                flexWrap={"wrap"}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Box>
                  <Typography
                    variant={"h1"}
                    fontSize={{ md: 36 }}
                    fontWeight={700}
                    mb={2}
                  >
                    {`${presta.client?.prenom} ${presta.client?.nom}`}
                  </Typography>
                </Box>
                <Box>
                  <Box mt={{ md: 4 }}>
                    <StyledToggleButtonGroup
                      color="primary"
                      value={alignment}
                      exclusive
                      /* onChange={handleChange} */
                      sx={{
                        borderRadius: "12px",
                        border: "1px solid rgba(62, 195, 236, 1)",
                        textAlign: "center",
                        display: { xs: "block", sm: "inline-flex" },
                        margin: { xs: "0 30px", sm: "0" },
                      }}
                    >
                      <ToggleButton
                        value="Particulier"
                        sx={{
                          fontFamily: "Bauhaus",
                          fontWeight: "700",
                          fontSize: 18,
                          padding: "10px 32px",
                          borderRadius: 4,
                          color: "#fff",
                        }}
                      >
                        Particulier
                      </ToggleButton>
                      <ToggleButton
                        value="Hôtel & résidence"
                        sx={{
                          fontFamily: "Bauhaus",
                          fontWeight: "700",
                          fontSize: 18,
                          padding: "10px 32px",
                          borderRadius: 4,
                          color: "#fff",
                        }}
                      >
                        Hôtel & résidence
                      </ToggleButton>
                      <ToggleButton
                        value="Professionnel"
                        sx={{
                          fontFamily: "Bauhaus",
                          fontWeight: "700",
                          fontSize: 18,
                          padding: "10px 30px",
                          borderRadius: 4,
                          color: "#fff",
                        }}
                      >
                        Professionnel
                      </ToggleButton>
                    </StyledToggleButtonGroup>
                  </Box>
                </Box>
              </Box>
              <Box width={{ xs: 390, md: 604 }}>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    lineHeight={2}
                  >
                    Référence :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                  >
                    {editReference ? (
                      <>
                        <TextAreaInput
                          register={{ ...register("reference") }}
                          styles={{ color: "#03EBFF!important" }}
                        />
                      </>
                    ) : (
                      presta.reference
                    )}

                    {errors.reference && (
                      <Typography
                        fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                        color="red"
                      >
                        {errors.reference.message}
                      </Typography>
                    )}

                    {editReference ? (
                      <>
                        <Button
                          variant={"text"}
                          onClick={() => setEditReference(false)}
                        >
                          Annuler
                        </Button>
                        <Button
                          variant={"text"}
                          onClick={handleSubmit(HandleReference)}
                        >
                          Enregistrer la référence{" "}
                          {load && (
                            <CircularProgress
                              size={14}
                              sx={{ marginLeft: "5px" }}
                            />
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant={"text"}
                        onClick={() => setEditReference(!editReference)}
                      >
                        {presta.reference
                          ? "Modifier la référence"
                          : "Ajouter la référence"}
                      </Button>
                    )}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    color={"primary"}
                  >
                    Problème :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.problemes.map((x) => `${x}`)}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    color={"primary"}
                  >
                    Type de bâtiment :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.type_batiment}
                  </Typography>
                </Box>
                {presta.client?.function_client && (
                  <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                    <Typography
                      variant="h2"
                      textTransform={"none"}
                      fontFamily={"Brandon Grotesque"}
                      font-weight={"500"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={160}
                      textAlign="right"
                      paddingRight={0.6}
                      color={"primary"}
                    >
                      Fonction :
                    </Typography>

                    <Typography
                      variant="h2"
                      textTransform={"none"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={{ xs: 230, md: 444 }}
                      color={"primary"}
                    >
                      {presta.client?.function_client}
                    </Typography>
                  </Box>
                )}
                {presta.client?.nom_societe && (
                  <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                    <Typography
                      variant="h2"
                      textTransform={"none"}
                      fontFamily={"Brandon Grotesque"}
                      font-weight={"500"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={160}
                      textAlign="right"
                      paddingRight={0.6}
                      color={"primary"}
                    >
                      Société :
                    </Typography>

                    <Typography
                      variant="h2"
                      textTransform={"none"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={{ xs: 230, md: 444 }}
                      color={"primary"}
                    >
                      {presta.client?.nom_societe}
                    </Typography>
                  </Box>
                )}
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    color={"primary"}
                  >
                    Adresse :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.adresse}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    color={"primary"}
                  >
                    Code postal :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.code_postal}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    color={"primary"}
                  >
                    Ville :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.ville}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    color={"primary"}
                  >
                    Téléphone :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.telephone}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    color={"primary"}
                  >
                    Email :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {presta.client?.email}
                  </Typography>
                </Box>

                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    color={"primary"}
                  >
                    Client depuis :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {moment
                      .parseZone(presta.client?.date_inscription)
                      .format("DD/MM/YYYY")}
                  </Typography>
                </Box>
                {presta.client?.message && (
                  <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                    <Typography
                      variant="h2"
                      textTransform={"none"}
                      fontFamily={"Brandon Grotesque"}
                      font-weight={"500"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={160}
                      textAlign="right"
                      paddingRight={0.6}
                      color={"primary"}
                    >
                      Message :
                    </Typography>

                    <Typography
                      variant="h2"
                      textTransform={"none"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={{ xs: 230, md: 444 }}
                      color={"primary"}
                    >
                      {presta.client?.message}
                    </Typography>
                  </Box>
                )}
                {presta.demandes?.length > 0 && (
                  <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                    <Typography
                      variant="h2"
                      textTransform={"none"}
                      fontFamily={"Brandon Grotesque"}
                      font-weight={"500"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={160}
                      textAlign="right"
                      paddingRight={0.6}
                      lineHeight={2}
                      color={"primary"}
                    >
                      Demandes :
                    </Typography>

                    <Typography
                      variant="h2"
                      textTransform={"none"}
                      fontSize={{ sm: 14, md: 18 }}
                      width={{ xs: 230, md: 444 }}
                      color={"primary"}
                    >
                      {presta.demandes.map((demande, i) => {
                        return (
                          <Box
                            key={i}
                            border={"1px solid rgba(3, 235, 255, 1)"}
                            padding={"10px"}
                            marginBottom={"10px"}
                          >
                            <Typography
                              variant="p"
                              fontSize={{ xs: 18 }}
                              lineHeight={"normal"}
                              textTransform={"none"}
                              color={"primary"}
                            >
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{ __html: demande }}
                              ></div>
                            </Typography>
                          </Box>
                        );
                      })}
                    </Typography>
                  </Box>
                )}
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    lineHeight={2}
                  >
                    Détail :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                  >
                    {editDetail ? (
                      <>
                        <TextAreaInput
                          register={{ ...register("detail") }}
                          styles={{ color: "#03EBFF!important" }}
                        />
                      </>
                    ) : (
                      presta.detail
                    )}

                    {errors.detail && (
                      <Typography
                        fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                        color="red"
                      >
                        {errors.detail.message}
                      </Typography>
                    )}

                    {editDetail ? (
                      <>
                        <Button
                          variant={"text"}
                          onClick={() => setEditDetail(false)}
                        >
                          Annuler
                        </Button>
                        <Button
                          variant={"text"}
                          onClick={handleSubmit(HandleDetail)}
                        >
                          Enregistrer le détail{" "}
                          {load && (
                            <CircularProgress
                              size={14}
                              sx={{ marginLeft: "5px" }}
                            />
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant={"text"}
                        onClick={() => setEditDetail(!editDetail)}
                      >
                        {presta.detail
                          ? "Modifier le détail"
                          : "Ajouter le détail"}
                      </Button>
                    )}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontFamily={"Brandon Grotesque"}
                    font-weight={"500"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                    lineHeight={2}
                  >
                    Note :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"none"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                  >
                    {editNote ? (
                      <>
                        <TextAreaInput
                          register={{ ...register("note") }}
                          styles={{ color: "#03EBFF!important" }}
                        />
                      </>
                    ) : (
                      presta.note
                    )}

                    {errors.note && (
                      <Typography
                        fontSize={{ xs: 14, margin: "-15px 0 15px" }}
                        color="red"
                      >
                        {errors.note.message}
                      </Typography>
                    )}

                    {editNote ? (
                      <>
                        <Button
                          variant={"text"}
                          onClick={() => setEditNote(false)}
                        >
                          Annuler
                        </Button>
                        <Button
                          variant={"text"}
                          onClick={handleSubmit(HandleNote)}
                        >
                          Envoyer la note{" "}
                          {load && (
                            <CircularProgress
                              size={14}
                              sx={{ marginLeft: "5px" }}
                            />
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant={"text"}
                        onClick={() => setEditNote(!editNote)}
                      >
                        {presta.note ? "Modifier la note" : "Ajouter une note"}
                      </Button>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={3}
            >
              <Box width={{ xs: 303, md: 604, lg: 303 }}>
                <Typography
                  fontWeight={{ xs: 700 }}
                  fontSize={{ xs: 18 }}
                  textAlign={{ xs: "center", md: "left" }}
                  textTransform={"uppercase"}
                  color={"rgba(255, 255, 255, 0.5)"}
                  mb={2}
                >
                  Phase du processus{" "}
                  <Button
                    startIcon={<Edit />}
                    variant={"text"}
                    color={"primary"}
                    sx={{ fontWeight: 700, textTransform: "capitalize" }}
                    onClick={() => setShowEditStatus(true)}
                  ></Button>
                </Typography>
                <Box
                  border={"1px solid rgba(3, 235, 255, 1)"}
                  padding={"24px 24px 12px"}
                >
                  <Box>
                    {(parseInt(presta.etape) === 1 ||
                      parseInt(presta.etape) > 1) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            1
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Appel téléphonique prévu le{" "}
                              <Typography
                                variant={"h2"}
                                fontSize={{ xs: 16 }}
                                color={"warning.main"}
                                component={"span"}
                              >
                                {moment
                                  .parseZone(dateAppel?.date_debut)
                                  .local()
                                  .format("DD/MM/YYYY")}{" "}
                                á{" "}
                                {moment
                                  .parseZone(dateAppel?.date_debut)
                                  .format("HH:mm")}
                              </Typography>{" "}
                              pour consultation.
                              {dateAppel?.date_prevu && (
                                <Box sx={{ textDecoration: "underline" }}>
                                  Date proposée par le client{" "}
                                  <Typography
                                    variant={"h2"}
                                    fontSize={{ xs: 16 }}
                                    color={"warning.main"}
                                    component={"span"}
                                  >
                                    {moment
                                      .parseZone(dateAppel?.date_prevu)
                                      .format("DD/MM/YYYY") +
                                      " à " +
                                      moment
                                        .parseZone(dateAppel?.date_prevu)
                                        .format("HH:mm")}
                                  </Typography>
                                </Box>
                              )}
                            </Typography>
                            {parseInt(presta.etape) === 1 && (
                              <Button
                                variant={"text"}
                                color={"primary"}
                                onClick={() => setEditRdv(true)}
                              >
                                Modifier
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(presta.etape) === 2 ||
                      parseInt(presta.etape) > 2) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            2
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Attribution partenaire
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(presta.etape) === 3 ||
                      parseInt(presta.etape) > 3) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            3
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              {presta.type_prestation === "devis_ignorer"
                                ? "Devis ignorer"
                                : "Création du devis"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(presta.etape) === 4 ||
                      parseInt(presta.etape) > 4) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            4
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              {datePlanification ? (
                                <>
                                  {presta.type_prestation !==
                                    "devis_ignorer" && (
                                    <>
                                      Devis Accepter <br />
                                    </>
                                  )}
                                  Planification prévu le{" "}
                                  <Typography
                                    variant={"h2"}
                                    fontSize={{ xs: 16 }}
                                    color={"warning.main"}
                                    component={"span"}
                                  >
                                    {moment
                                      .parseZone(datePlanification.date_debut)
                                      .format("DD/MM/YYYY")}{" "}
                                    á{" "}
                                    {moment
                                      .parseZone(datePlanification.date_debut)
                                      .format("HH:mm")}
                                  </Typography>
                                  {datePlanification?.date_prevu && (
                                    <Box sx={{ textDecoration: "underline" }}>
                                      Date proposée par le client{" "}
                                      <Typography
                                        variant={"h2"}
                                        fontSize={{ xs: 16 }}
                                        color={"warning.main"}
                                        component={"span"}
                                      >
                                        {moment
                                          .parseZone(
                                            datePlanification?.date_prevu
                                          )
                                          .format("DD/MM/YYYY") +
                                          " à " +
                                          moment
                                            .parseZone(
                                              datePlanification?.date_prevu
                                            )
                                            .format("HH:mm")}
                                      </Typography>
                                    </Box>
                                  )}
                                  {(parseInt(presta.etape) === 6 ||
                                    parseInt(presta.etape) === 5) && (
                                    <Button
                                      variant={"text"}
                                      color={"primary"}
                                      onClick={() => setEditRdv(true)}
                                    >
                                      Modifier
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  {presta.type_prestation === "devis_ignorer"
                                    ? "Devis ignorer"
                                    : "Devis envoyer"}
                                </>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(presta.etape) === 6 ||
                      parseInt(presta.etape) > 6) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            5
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              {presta.type}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(presta.etape) === 7 ||
                      parseInt(presta.etape) > 7) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            6
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Intervention en cours{" "}
                              {partner && (
                                <>
                                  de{" "}
                                  <Typography
                                    variant={"h2"}
                                    fontSize={{ xs: 16 }}
                                    color={"warning.main"}
                                    component={"span"}
                                  >
                                    {partner?.nom} {partner?.prenom}
                                  </Typography>
                                </>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(presta.etape) === 8 ||
                      parseInt(presta.etape) > 8) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            7
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Intervention terminer
                              {(parseInt(presta.etape) === 9 ||
                                parseInt(presta.etape) > 9) && (
                                <>
                                  <br></br>- FI signer par le partenaire
                                </>
                              )}
                              {!presta.client.client_fibme &&
                                (parseInt(presta.etape) === 10 ||
                                  parseInt(presta.etape) > 10) && (
                                  <>
                                    <br></br>- FI signer par le client
                                  </>
                                )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(presta.etape) === 11 ||
                      parseInt(presta.etape) > 11) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            8
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Paiement confirmer
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {/* <Box mt={1.6}>
                    <Typography
                      fontSize={{ xs: 16 }}
                      fontStyle={{ xs: "italic" }}
                      mb={1}
                    >
                      Intervenant :{" "}
                      <Typography
                        variant={"h2"}
                        fontSize={{ xs: 16 }}
                        component={"span"}
                        fontStyle={"normal"}
                      >
                        Corey Septimus
                      </Typography>
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
              <Box width={{ xs: 303, md: 604, lg: 303 }}>
                <Typography
                  fontWeight={{ xs: 700 }}
                  fontSize={{ xs: 18 }}
                  textAlign={{ xs: "center", md: "left" }}
                  textTransform={"uppercase"}
                  color={"rgba(255, 255, 255, 0.5)"}
                  mb={2}
                >
                  Partenaire & planification
                </Typography>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={{ xs: "column", md: "row", lg: "column" }}
                  flexWrap={"wrap"}
                  gap={1}
                  width={"100%"}
                >
                  {!partner ? (
                    <Box
                      width={{ xs: 303, md: 293 }}
                      height={70}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      border={"1px solid rgba(3, 235, 255, 1)"}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        parseInt(presta.etape) < 6
                          ? setShowAddPartenaire(true)
                          : console.log();
                      }}
                    >
                      <Typography
                        fontWeight={{ xs: 300 }}
                        fontSize={{ xs: 24 }}
                      >
                        Attribuer
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <Box
                        width={{ xs: 303, md: 293 }}
                        height={70}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        border={"1px solid rgba(3, 235, 255, 1)"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          if (parseInt(presta.etape) < 6) {
                            setShowAddPartenaire(true);
                            setPartner(partner);
                          }
                        }}
                      >
                        <Typography
                          fontWeight={{ xs: 300 }}
                          fontSize={{ xs: 24 }}
                        >
                          {partner.nom} {partner.prenom}
                        </Typography>
                      </Box>
                      {parseInt(presta.etape) > 1 &&
                        parseInt(presta.etape) < 7 && (
                          <Box
                            width={{ xs: 303, md: 293 }}
                            height={70}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            border={"1px solid rgba(3, 235, 255, 1)"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowPlanification(true);
                              setPartner(partner);
                            }}
                          >
                            <Typography
                              fontWeight={{ xs: 300 }}
                              fontSize={{ xs: 24 }}
                            >
                              Planifier
                            </Typography>
                          </Box>
                        )}
                    </>
                  )}
                </Box>
              </Box>
              <Box width={{ xs: 303, md: 604, lg: 303 }}>
                <Typography
                  fontWeight={{ xs: 700 }}
                  fontSize={{ xs: 18 }}
                  textAlign={{ xs: "center", md: "left" }}
                  textTransform={"uppercase"}
                  color={"rgba(255, 255, 255, 0.5)"}
                  mb={2}
                >
                  Documents
                </Typography>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={{ xs: "column", md: "row", lg: "column" }}
                  flexWrap={"wrap"}
                  gap={1}
                  width={"100%"}
                >
                  {/*  {presta.images.length > 0 && ( */}
                  <Box
                    width={{ xs: 303, md: 293 }}
                    height={70}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    border={"1px solid rgba(3, 235, 255, 1)"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => setShowImages(true)}
                  >
                    <Typography fontWeight={{ xs: 300 }} fontSize={{ xs: 24 }}>
                      Photos client
                    </Typography>
                  </Box>
                  {/*   )} */}
                  <Box
                    width={{ xs: 303, md: 293 }}
                    height={70}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    border={"1px solid rgba(3, 235, 255, 1)"}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      props.showDocument(document_type_order_travail)
                    }
                  >
                    <Typography fontWeight={{ xs: 300 }} fontSize={{ xs: 24 }}>
                      Order de travail
                    </Typography>
                  </Box>
                  {!detailDevis && parseInt(presta.etape) < 4 ? (
                    <Box
                      width={{ xs: 303, md: 293 }}
                      height={70}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      border={"1px solid rgba(3, 235, 255, 1)"}
                      sx={{ cursor: "pointer" }}
                      onClick={props.createDevis}
                    >
                      <Typography
                        fontWeight={{ xs: 300 }}
                        fontSize={{ xs: 24 }}
                      >
                        Créer un devis
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      {[
                        { id: document_type_devis, titre: "Devis" },
                        {
                          id: document_type_fiche_intervention,
                          titre: "Fiche d’intervention",
                        },
                        {
                          id: document_type_bon_intervention,
                          titre: "Bon d’intervention",
                        },
                        { id: document_type_facture, titre: "Facture" },
                      ].map((x, i) => {
                        if (
                          presta.documents?.find(
                            (obj) =>
                              obj.type.toLowerCase() === x.id.toLowerCase()
                          )
                        ) {
                          return (
                            <Box
                              key={i}
                              width={{ xs: 303, md: 293 }}
                              height={70}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              border={"1px solid rgba(3, 235, 255, 1)"}
                              sx={{ cursor: "pointer" }}
                              onClick={() => props.showDocument(x.id)}
                            >
                              <Typography
                                fontWeight={{ xs: 300 }}
                                fontSize={{ xs: 24 }}
                              >
                                {x.titre}
                              </Typography>
                            </Box>
                          );
                        }
                      })}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {showAddPartenaire && (
        <AddNewPartnerForm
          dialog={showAddPartenaire}
          onClose={() => setShowAddPartenaire(false)}
          demande={presta}
          partner={partner}
          setPartner={setPartner}
          setLoadPresta={() => fetchData(props.presta)}
        />
      )}

      {showEditStatus && (
        <EditStatusForm
          dialog={showEditStatus}
          onClose={() => setShowEditStatus(false)}
          demande={presta}
          setLoadPresta={() => fetchData(props.presta)}
        />
      )}

      {showImages && (
        <ImagesList
          dialog={showImages}
          onClose={() => setShowImages(false)}
          demande={presta}
        />
      )}

      {showPlanification && (
        <PlanificationForm
          dialog={showPlanification}
          onClose={() => setShowPlanification(false)}
          demande={presta}
          datePlanification={datePlanification}
          setDatePlanification={setDatePlanification}
          partner={partner}
          setPartner={setPartner}
          setLoadPresta={() => fetchData(props.presta)}
        />
      )}

      {editRdv && (
        <EditRdvForm
          dialog={editRdv}
          onClose={() => setEditRdv(false)}
          demande={presta}
          dateAppel={dateAppel}
          setDateAppel={setDateAppel}
          datePlanification={datePlanification}
          setDatePlanification={setDatePlanification}
          setLoadPresta={() => fetchData(props.presta)}
        />
      )}
    </Box>
  );
}
