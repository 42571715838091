import {
  Box,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
} from "@mui/material";
import {
  KeyboardBackspaceOutlined,
  EditOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import admin from "../../../assets/images/admin.png";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import ring from "../../../assets/images/ring.png";
import clientsService from "../../../store/apis/clientsApis";
import moment from "moment";
import {
  document_type_bon_intervention,
  document_type_devis,
  document_type_facture,
  document_type_fiche_intervention,
  rdv_type_call,
  rdv_type_prestation,
} from "../../../config/keys";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    //margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 16,
    },
    "&:first-of-type": {
      borderRadius: 16,
    },
  },
}));

export default function ProfileClient(props) {
  const { openDialog, onEditClose, id, setEditCongrat, setEdit, setRemove } =
    props;
  const [alignment, setAlignment] = useState("Professionnel");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);
  const [datePlanification, setDatePlanification] = useState();
  const [dateAppel, setDateAppel] = useState();

  useEffect(() => {
    setLoad(true);
    clientsService
      .detailClient(id)
      .then((res) => {
        setLoad(false);

        setData(res);

        setDateAppel(res.calendar?.filter((rdv) => rdv.type === rdv_type_call).pop());
        setDatePlanification(
          res.calendar?.filter((rdv) => rdv.type === rdv_type_prestation).pop()
        );
      })
      .catch((e) => {
        setLoad(false);
        setError(e);
      });
  }, [id]);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const formatDate = (dte) => {
    dte = new Date(dte);

    return dte.getDay() + "/" + dte.getMonth() + "/" + dte.getFullYear();
  };

  const showDocument = (document) => {
    console.log(document);
  };

  if (error)
    return (
      <Box>
        <Typography fontSize={{ xs: 16 }} color="red">
          Something Went Wrong
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ color: "#fff" }}>
      <Button
        startIcon={<KeyboardBackspaceOutlined />}
        variant={"text"}
        color={"primary"}
        sx={{ fontWeight: 700, textTransform: "capitalize" }}
        onClick={props.goBack}
      >
        Retour
      </Button>
      {load && (
        <Box
          height={"100%"}
          width={"100%"}
          display={"flex"}
          alignitems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Box>
      )}
      {data && (
        <>
          <Box
            display={"flex"}
            alignItems={{ xs: "center", lg: "start" }}
            justifyContent={{ xs: "center", lg: "space-between" }}
            flexDirection={{ xs: "column", lg: "row" }}
            gap={{ xs: 3 }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContentt={"center"}
              flexDirection={"column"}
              gap={{ xs: 3, md: 6 }}
            >
              <Box
                display={"flex"}
                alignItems={{ xs: "center", md: "start" }}
                justifyContent={{ xs: "center", md: "start" }}
                gap={{ xs: 3, md: 6, lg: 1 }}
                flexWrap={"wrap"}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Box>
                  <Typography
                    variant={"body2"}
                    fontWeight={"700"}
                    textTransform={"uppercase"}
                    fontSize={{ md: 24 }}
                    mt={2}
                    mb={2}
                  >
                    Profil Client
                  </Typography>
                  <Typography
                    variant={"h1"}
                    fontSize={{ md: 36 }}
                    fontWeight={700}
                    mb={2}
                  >
                    {`${data.prenom} ${data.nom}`}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    display={"flex"}
                    alignItems={"end"}
                    justifyContent={{ xs: "center", md: "end" }}
                    flexDirection={{ md: "column" }}
                    gap={{ xs: 2, md: 0 }}
                    flexGrow={{ md: 2 }}
                  >
                    <Button
                      startIcon={<EditOutlined />}
                      variant={"text"}
                      sx={{
                        fontWeight: { xs: 700 },
                        fontSize: { xs: 16 },
                        fontFamily: { xs: "Bauhaus" },
                        textTransform: "capitalize",
                      }}
                      color="inherit"
                      onClick={() => setEdit(true)}
                    >
                      Modifier
                    </Button>
                    <Button
                      startIcon={<DeleteOutline />}
                      variant={"text"}
                      sx={{
                        fontWeight: { xs: 700 },
                        fontSize: { xs: 16 },
                        fontFamily: { xs: "Bauhaus" },
                        textTransform: "capitalize",
                      }}
                      color="inherit"
                      onClick={() => setRemove(true)}
                    >
                      Supprimer
                    </Button>
                  </Box>
                  <Box mt={{ md: 4 }}>
                    <StyledToggleButtonGroup
                      color="primary"
                      value={data.type_client}
                      exclusive
                      onChange={handleChange}
                      sx={{
                        borderRadius: "12px",
                        border: "1px solid rgba(62, 195, 236, 1)",
                        textAlign: "center",
                        display: { xs: "block", sm: "inline-flex" },
                        margin: { xs: "0 30px", sm: "0" },
                      }}
                    >
                      <ToggleButton
                        value="Particulier"
                        sx={{
                          fontFamily: "Bauhaus",
                          fontWeight: "700",
                          fontSize: 18,
                          padding: "10px 32px",
                          borderRadius: 4,
                          color: "#fff",
                        }}
                      >
                        Particulier
                      </ToggleButton>
                      <ToggleButton
                        value="Hôtel & résidence"
                        sx={{
                          fontFamily: "Bauhaus",
                          fontWeight: "700",
                          fontSize: 18,
                          padding: "10px 32px",
                          borderRadius: 4,
                          color: "#fff",
                        }}
                      >
                        Hôtel & résidence
                      </ToggleButton>
                      <ToggleButton
                        value="Professionnel"
                        sx={{
                          fontFamily: "Bauhaus",
                          fontWeight: "700",
                          fontSize: 18,
                          padding: "10px 30px",
                          borderRadius: 4,
                          color: "#fff",
                        }}
                      >
                        Professionnel
                      </ToggleButton>
                    </StyledToggleButtonGroup>
                  </Box>
                </Box>
              </Box>
              <Box width={{ xs: 390, md: 604 }}>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Problème :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {data.problemes.map((x) => `${x}`)}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Type de bâtiment :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {data.demandes_client[0].type_batiment}
                  </Typography>
                </Box>
                {data.type_client !== "Particulier" && (
                  <>
                    <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                      <Typography
                        variant="h2"
                        textTransform={"capitalize"}
                        fontSize={{ sm: 14, md: 18 }}
                        width={160}
                        textAlign="right"
                        paddingRight={0.6}
                      >
                        Fonction :
                      </Typography>

                      <Typography
                        variant="h2"
                        textTransform={"capitalize"}
                        fontSize={{ sm: 14, md: 18 }}
                        width={{ xs: 230, md: 444 }}
                        color={"primary"}
                      >
                        {data.function_client}
                      </Typography>
                    </Box>
                    <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                      <Typography
                        variant="h2"
                        textTransform={"capitalize"}
                        fontSize={{ sm: 14, md: 18 }}
                        width={160}
                        textAlign="right"
                        paddingRight={0.6}
                      >
                        Société :
                      </Typography>

                      <Typography
                        variant="h2"
                        textTransform={"capitalize"}
                        fontSize={{ sm: 14, md: 18 }}
                        width={{ xs: 230, md: 444 }}
                        color={"primary"}
                      >
                        {data.nom_societe}
                      </Typography>
                    </Box>
                  </>
                )}
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Adresse :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {data.adresse}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Code postal :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {data.code_postal}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Ville :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {data.ville}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    tél :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {data.telephone}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Email :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {data.email}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Message :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {data.message}
                  </Typography>
                </Box>
                <Box display={"flex"} width={"100%"} mb={{ xs: 1, md: 2 }}>
                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={160}
                    textAlign="right"
                    paddingRight={0.6}
                  >
                    Client depuis :
                  </Typography>

                  <Typography
                    variant="h2"
                    textTransform={"capitalize"}
                    fontSize={{ sm: 14, md: 18 }}
                    width={{ xs: 230, md: 444 }}
                    color={"primary"}
                  >
                    {moment
                      .parseZone(data.date_inscription)
                      .format("DD/MM/YYYY")}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={3}
            >
              <Box width={{ xs: 303, md: 604, lg: 303 }}>
                <Typography
                  fontWeight={{ xs: 700 }}
                  fontSize={{ xs: 18 }}
                  textAlign={{ xs: "center", md: "left" }}
                  textTransform={"uppercase"}
                  color={"rgba(255, 255, 255, 0.5)"}
                  mb={2}
                >
                  prestations
                </Typography>
                <Box
                  border={"1px solid rgba(3, 235, 255, 1)"}
                  padding={"12px 24px"}
                >
                  <Box>
                    <Typography
                      fontSize={{ xs: 16 }}
                      fontStyle={{ xs: "italic" }}
                      mb={1}
                    >
                      Phase du processus :
                    </Typography>
                    {(parseInt(data.demandes_client[0].etape) === 1 ||
                      parseInt(data.demandes_client[0].etape) > 1) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            1
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Appel téléphonique prévu le{" "}
                              <Typography
                                variant={"h2"}
                                fontSize={{ xs: 16 }}
                                color={"warning.main"}
                                component={"span"}
                              >
                                {moment
                                  .parseZone(dateAppel?.date_debut)
                                  .local()
                                  .format("DD/MM/YYYY")}{" "}
                                á{" "}
                                {moment
                                  .parseZone(dateAppel?.date_debut)
                                  .format("HH:mm")}
                              </Typography>{" "}
                              pour consultation.
                              {dateAppel?.date_prevu && (
                                <Box sx={{ textDecoration: "underline" }}>
                                  Date proposée par le client{" "}
                                  <Typography
                                    variant={"h2"}
                                    fontSize={{ xs: 16 }}
                                    color={"warning.main"}
                                    component={"span"}
                                  >
                                    {moment
                                      .parseZone(dateAppel?.date_prevu)
                                      .format("DD/MM/YYYY") +
                                      " à " +
                                      moment
                                        .parseZone(dateAppel?.date_prevu)
                                        .format("HH:mm")}
                                  </Typography>
                                </Box>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(data.demandes_client[0].etape) === 2 ||
                      parseInt(data.demandes_client[0].etape) > 2) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            2
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Création du devis
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {(parseInt(data.demandes_client[0].etape) === 3 ||
                      parseInt(data.demandes_client[0].etape) > 3) && (
                      <Box display={"flex"} gap={2} marginBottom={"16px"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          width={75}
                          height={61}
                          sx={{
                            backgroundImage: `url(${ring})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <Typography
                            fontSize={{ xs: 24 }}
                            padding={"0px 10px"}
                            color={"primary.main"}
                          >
                            3
                          </Typography>
                        </Box>
                        <Box
                          flexBasis={{ xs: "65%", md: "auto", lg: "65%" }}
                          display={"flex"}
                          justifyContent={"space-between"}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                            lg: "column",
                          }}
                        >
                          <Box flexBasis={"70%"}>
                            <Typography
                              variant={"h2"}
                              fontSize={{ xs: 16 }}
                              component={"p"}
                            >
                              Création de la fiche d'intervention & attribution
                              du partenaire
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {/* <Box mt={1.6}>
                    <Typography
                      fontSize={{ xs: 16 }}
                      fontStyle={{ xs: "italic" }}
                      mb={1}
                    >
                      Intervenant :{" "}
                      <Typography
                        variant={"h2"}
                        fontSize={{ xs: 16 }}
                        component={"span"}
                        fontStyle={"normal"}
                      >
                        Corey Septimus
                      </Typography>
                    </Typography>
                  </Box> */}
                </Box>
              </Box>

              <Box width={{ xs: 303, md: 604, lg: 303 }}>
                <Typography
                  fontWeight={{ xs: 700 }}
                  fontSize={{ xs: 18 }}
                  textAlign={{ xs: "center", md: "left" }}
                  textTransform={"uppercase"}
                  color={"rgba(255, 255, 255, 0.5)"}
                  mb={2}
                >
                  Documents
                </Typography>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={{ xs: "column", md: "row", lg: "column" }}
                  flexWrap={"wrap"}
                  gap={1}
                  width={"100%"}
                >
                  {parseInt(data.demandes_client[0].etape) === 1 ? (
                    <></>
                  ) : (
                    <>
                      {[
                        { id: document_type_devis, titre: "Devis" },
                        {
                          id: document_type_fiche_intervention,
                          titre: "Fiche d’intervention",
                        },
                        {
                          id: document_type_bon_intervention,
                          titre: "Bon d’intervention",
                        },
                        { id: document_type_facture, titre: "Facture" },
                      ].map((x, i) => {
                        if (
                          data.demandes_client[0].documents.find(
                            (obj) =>
                              obj.type.toLowerCase() === x.id.toLowerCase()
                          )
                        ) {
                          return (
                            <Box
                              key={i}
                              width={{ xs: 303, md: 293 }}
                              height={70}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              border={"1px solid rgba(3, 235, 255, 1)"}
                              sx={{ cursor: "pointer" }}
                              onClick={() => showDocument(x.id)}
                            >
                              <Typography
                                fontWeight={{ xs: 300 }}
                                fontSize={{ xs: 24 }}
                              >
                                {x.titre}
                              </Typography>
                            </Box>
                          );
                        }
                      })}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
