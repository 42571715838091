import { useTheme } from "@emotion/react";
import { Box, Dialog, Paper, Slide, useMediaQuery } from "@mui/material";
import { forwardRef, useState } from "react";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Footer, NavBar } from "../../../components";
import { BlackButton } from "../../../components/buttons";
import btn from "../../../assets/images/bg_btn.png";
import { reset } from "../../../store/slices/userAuthReducer";
import {
  Why,
  About,
  Testimonials,
  SignIn,
  ForgotPassword,
  RestorePassword,
  Contact,
  MessageCongrats,
  EmailCongrats,
} from "./sections";
import ClockSlider from "./sections/ClockSlider";
import { useNavigation } from "@react-navigation/native";
import Setting from "./sections/SettingSection";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Home() {
  const navigation = useNavigation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const { user } = useSelector((state) => state.userAuth);
  const [page, setPage] = useState("Sign_In");
  const dispatch = useDispatch();
  const [congrat, setCongrat] = useState(false);
  const [email, setEmail] = useState(false);

  const [dialog, setDialog] = useState(false);
  const handleDialog = () => {
    if (dialog) dispatch(reset());
    setDialog(!dialog);
    setPage("Sign_In");
  };

  const [contact, setContact] = useState(false);
  const handleContact = () => setContact(!contact);

  return (
    <Paper style={{ backgroundColor: theme.palette.background.main }}>
      <Dialog
        open={user ? false : dialog}
        TransitionComponent={Transition}
        fullScreen={!isMd}
        PaperProps={{
          sx: {
            maxWidth: { md: 670 },
            width: { md: 670 },
            borderRadius: { md: 4 },
            boxShadow: "none",
          },
        }}
        onClose={() => {
          dispatch(reset());
          setDialog(!dialog);
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: theme.palette.background.backdropModal,
          },
        }}
      >
        {(() => {
          switch (page) {
            case "Sign_In":
              return <SignIn setPage={setPage} handleDialog={handleDialog} />;

            case "Forgot_Password":
              return (
                <ForgotPassword
                  setPage={setPage}
                  handleDialog={handleDialog}
                  setCongrat={setEmail}
                />
              );

            case "Restore_Password":
              return <RestorePassword handleDialog={handleDialog} />;
          }
        })()}
      </Dialog>
      <Dialog
        open={!user ? false : dialog}
        TransitionComponent={Transition}
        fullScreen={!isMd}
        PaperProps={{
          sx: {
            maxWidth: { md: 670 },
            width: { md: 670 },
            borderRadius: { md: 4 },
            boxShadow: "none",
          },
        }}
        onClose={() => {
          dispatch(reset());
          setDialog(!dialog);
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: theme.palette.background.backdropModal,
          },
        }}
      >
        <Setting handleDialog={handleDialog} />
      </Dialog>
      <Contact
        contact={contact}
        handleContact={handleContact}
        setCongrat={setCongrat}
      />
      <MessageCongrats congrat={congrat} setCongrat={setCongrat} />
      <EmailCongrats email={email} setEmail={setEmail} />

      <NavBar handleDialog={handleDialog} />
      <Box
        display={{ xs: "flex", md: "none" }}
        alignItems={"center"}
        justifyContent={"center"}
        mt={2}
      >
        <BlackButton
          shadow
          textRainbow
          fullwidth
          handleClick={() => navigation.navigate("Register")}
        >
          prendre un rendez-vous
        </BlackButton>
      </Box>
      <About />
      <ClockSlider />
      <Why />
      {/* <Slider /> */}
      <Testimonials />
      <Footer handleContact={handleContact} />
    </Paper>
  );
}
