// import styled from "styled-components";
// import logo from "../../assets/images/icons/user.png";

// const Link = styled.a`
//   position: relative;
//   display: block;
//   width: 65px;
//   height: 65px;
//   border-radius: 50%;
//   border: 2px solid #002c3a;
//   padding: 7px;
// `;

// const Span = styled.span`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   height: 100%;
//   border: 2px solid #002c3a;
//   border-radius: 50%;
//   position: relative;
//   &:after,
//   &:before {
//     content: "";
//     position: absolute;
//     width: 7px;
//     height: 7px;
//     background-color: #03ebff;
//     left: 50%;
//     top: 50%;
//     transform: translate3d(18px, -6px, 0);
//     border-radius: 50%;
//   }
//   &::before {
//     width: 12px;
//     height: 12px;
//     transform: translate3d(17px, -27px, 0);
//   }
// `;

// const Img = styled.img`
//   height: 22px;
//   s
// `;

// const AccountButton = () => {
//   return (
//     <Link>
//       <Span>
//         <Img src={logo} alt="logo" />
//       </Span>
//     </Link>
//   );
// };

// export default AccountButton;

import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled, ThemeProvider } from "@mui/system";
import user from "../../assets/images/icons/user.png";
import btn from "../../assets/images/bg_btn.png";
import theme from "../../assets/theme";
import CircleButton from "../buttons/CircleButton";
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../../store/slices/userAuthReducer";
import { useNavigation } from "@react-navigation/native";

const AccountWrap = styled("div")(({ theme }) => ({
  position: "relative",
  "&.on": {
    ".dropdown": {
      opacity: 1,
      visibility: "visible",
    },
  },
}));

const Dropdown = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "calc(100% - 49px)",
  right: 0,
  opacity: 0,
  visibility: "hidden",
  transition: "all .3s ease-in-out",
  ul: {
    listStyleType: "none",
  },
}));

const Li = styled("li")(({ theme }) => ({
  marginBottom: "5px",
  cursor: "pointer",
}));

const LiLink = styled("a")(({ theme }) => ({
  position: "relative",
  display: "block",
  backgroundImage: "url(" + btn + ")",
  backgroundSize: "100% 100%",
  whiteSpace: "nowrap",
  padding: "0 15px",
  lineHeight: "50px",
  fontFamily: theme.typography.fontFamily,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  textAlign: "center",
  color: theme.palette.text.primary,
  transition: "all .3s ease-in-out",
}));

const Img = styled("img")(({ theme }) => ({
  display: "inline-block",
}));

const Link = styled("a")(({ theme }) => ({
  display: "inline",
}));

const AccountButton = ({ handleClick, admin }) => {
  const state = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const navigation = useNavigation();

  const onClick = () => {
    if (!state.user) return handleClick();
    setActive(!active);
  };
  const logout = () => {
    setActive(false);
    dispatch(userLogout());
    navigation.navigate("Home");
  };

  const tableau = () => {
    setActive(false);
    if (
      state.user?.data?.role == "ROLE_CLIENT" ||
      state.user?.user?.data?.role === "ROLE_CLIENT"
    ) {
      navigation.navigate("Dashboard");
    } else if (
      state.user?.data?.role == "ROLE_ADMIN" ||
      state.user?.data?.role == "ROLE_SUPER_ADMIN" ||
      state.user?.user?.data?.role === "ROLE_ADMIN" ||
      state.user?.user?.data?.role === "ROLE_SUPER_ADMIN"
    ) {
      navigation.navigate("AdminDashboard");
    } else if (
      state.user?.data?.role == "ROLE_ACTIVITE" ||
      state.user?.user?.data?.role === "ROLE_ACTIVITE"
    ) {
      navigation.navigate("ActivityDashboard");
    } else if (
      state.user?.data?.role == "ROLE_FACTURATION" ||
      state.user?.user?.data?.role === "ROLE_FACTURATION"
    ) {
      navigation.navigate("FacturationDashboard");
    } else if (
      state.user?.data?.role == "ROLE_PRESTATAIRE" ||
      state.user?.user?.data?.role === "ROLE_PRESTATAIRE"
    ) {
      navigation.navigate("PartnerDashboard");
    } else {
      navigation.navigate("Home");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AccountWrap className={"account " + (active ? "on" : "")}>
        <CircleButton onClick={onClick} admin={admin}>
          <Img src={user} />
        </CircleButton>
        {state.user && (
          <Typography
            fontFamily={"Bauhaus"}
            color={"#002C3A"}
            fontSize={{ xs: 12, lg: 12 }}
          >
            {state.user?.data?.nom} {state.user?.data?.prenom}{" "}
            {state.user?.user?.data?.nom} {state.user?.user?.data?.prenom}
          </Typography>
        )}
        <Dropdown className="dropdown">
          <Box component="ul">
            <Li onClick={tableau}>
              <LiLink>Tableau de bord</LiLink>
            </Li>
            {(state.user?.data?.role === "ROLE_PRESTATAIRE" ||
              state.user?.user?.data?.role === "ROLE_PRESTATAIRE") && (
              <Li>
                <LiLink onClick={() => navigation.navigate("PartnerProfile")}>
                  Mon compte
                </LiLink>
              </Li>
            )}
            {/*<Li>
              <LiLink href="">Devenir intervenant</LiLink>
            </Li> */}
            <Li onClick={logout}>
              <LiLink>Déconnexion</LiLink>
            </Li>
          </Box>
        </Dropdown>
      </AccountWrap>
    </ThemeProvider>
  );
};

export default AccountButton;
