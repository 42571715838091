import React, { useEffect, useState } from "react";
import {
  Calendar,
  CalendarList,
  Agenda,
  LocaleConfig,
} from "react-native-calendars";
import { styled } from "@mui/system";
import { Box, CircularProgress } from "@mui/material";
import moment from "moment";
import classNames from "classnames";
import theme from "../../assets/theme";
import { SignButton } from "../buttons";
import CalendarService from "../../store/apis/CalendarApi";
import dateFormat from "dateformat";

LocaleConfig.locales["fr"] = {
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthNamesShort: [
    "Janv.",
    "Févr.",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juil.",
    "Août",
    "Sept.",
    "Oct.",
    "Nov.",
    "Déc.",
  ],
  dayNames: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],
  dayNamesShort: ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
  today: "Aujourd'hui",
};
LocaleConfig.defaultLocale = "fr";
const CalendarWrap = styled("div")(({ theme }) => ({
  borderRadius: 20,
  margin: "30px 0",

  ".css-text-901oao, .css-901oao, .p": {
    marginTop: 20,
    fontFamily: theme.typography.fontFamilyBauhaus,
    fontSize: 18,
    lineHeight: "21px",
    display: "block",
  },
  ".callayout": {
    display: "flex",
  },
  ".calbox": {
    flex: 1,
    marginRight: 25,
    backgroundImage:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(66, 199, 241, 0.122) 100%)",
    border: "1px solid rgba(66, 199, 241, 0.35)",
    padding: "0 30px 50px",

    ".r-backgroundColor-14lw9ot, .r-14lw9ot": {
      backgroundColor: "transparent",
    },
    ".r-width-1yvhtrz, .r-1yvhtrz": {
      width: 40,
    },
    '.r-height-mabqd8[role="button"], .r-mabqd8[role="button"]': {
      height: 36,
      background: "#f2F2F2",
      border: "1px solid #3dc3ec",
    },
    ".r-borderRadius-1867qdf, .r-1867qdf": {
      borderRadius: "0%",
    },
  },
  ".cre-list": {
    backgroundImage:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(66, 199, 241, 0.122) 100%)",
    border: "1px solid rgba(66, 199, 241, 0.35)",
    padding: 20,
    width: 190,

    span: {
      fontFamily: theme.typography.fontFamilyBauhaus,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "19px",
      textAlign: "center",
      color: "#002C3A",
      display: "block",
    },
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    border: "none",
    padding: 0,

    ".callayout": {
      flexDirection: "column",
      ".calbox": {
        marginRight: 0,
        marginBottom: 15,
      },
      ".cre-list": {
        width: "100%",
      },
    },
  },
}));
const CalendarLayout = styled("div")(({ theme }) => ({
  marginTop: 10,

  ".css-text-901oao, .css-901oao, .p": {
    fontSize: 15,
    margin: 0,
    lineHeight: "32px",
  },
  ".r-flex-13awgt0, .r-13awgt0": {
    backgroundColor: "rgba(14, 69, 74, 0.04)",
    margin: 2,
  },
  ".r-marginVertical-sfmlkt, .r-sfmlkt": {
    margin: 0,
  },
  ".r-paddingRight-1qfoi16, .r-1qfoi16": {
    justifyContent: "center",
    ".r-flexDirection-18u37iz, .r-18u37iz": {
      margin: "0 15px",
      minWidth: 116,
      justifyContent: "center",
    },
    ".r-padding-1sp51qo, .r-1sp51qo": {
      width: 35,
      height: 35,
      borderRadius: 35,
      backgroundColor: theme.palette.text.primary,
      color: "#fff",
      position: "relative",

      ".left, .right": {
        content: '""',
        position: "absolute",
        left: "50%",
        top: "50%",
        width: 11,
        height: 11,
        borderBottom: "2px solid #fff",
        borderRight: "2px solid #fff",
        transform: "translate(calc(-50% - 2px), -50%) rotate(-45deg)",
      },
      ".left": {
        transform: "translate(calc(-50% + 2px), -50%) rotate(135deg)",
      },
    },
  },
  ".dates-list": {
    listStyleType: "none",
    padding: "0",
    margin: "15px 0",
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: theme.typography.fontFamilyBauhaus,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    li: {
      padding: "2px 0",
      textAlign: "left",
      width: "calc(50% - 5px)",
      border: "1px solid #444",
      textAlign: "center",
      marginBottom: 10,
      boxShadow: "2px 2px 0 #bbb",
      trsansition: "all .4s ease-in-out",

      "&.active": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  ".calbox ": {
    paddingTop: 15,
    position: "relative",

    ".r-color-jwli3a, .r-jwli3a": {
      width: "100%",
      borderRadius: 0,
      border: "1px solid #3EC3EC",
      textAlign: "center",
      color: "#fff",
    },
    ".r-width-1yvhtrz, .r-1yvhtrz": {
      width: "100%",
      borderRadius: 0,
      /* background: "transparent!important",
      border: "none!important", */
      /* color: "#fff", */

      '&[aria-disabled="true"]': {
        /* background: "transparent!important",
        border: "none!important", */
        /* color: "#fff", */
        border: "none",
      },
    },
  },
  ".r-justifyContent-a2tzq0, .r-a2tzq0": {
    ".r-color-zd8e7p": {
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
  ".r-marginTop-1cvj4g8, .r-1cvj4g8": {
    top: "-8px",
  },
}));

export default function CustomCalendar({ loading, onHourSelected }) {
  const [dates, setDates] = useState({});
  const [currentMonth, setCurrentMonth] = useState(
    dateFormat(new Date(), "yyyy-mm-dd")
  );
  const [availableHours, setAvailableHours] = useState({});
  const [hours, setHours] = useState([
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
  ]);
  const [currentDate, setCurrentDate] = useState(false);
  const [selectedDay, setSelectedDay] = useState(false);
  const [selectedHour, setSelectedHour] = useState(false);

  const onDaySelect = (day) => {
    const _selectedDay = moment.parseZone(day.dateString).format("YYYY-MM-DD");

    const Dates = {};

    Dates[_selectedDay] = {
      selected: true,
      marked: false,
      dotColor: "#444",
      selectedColor: "#f4f2f6",
      hours: [],
    };

    // if(!Dates[_selectedDay]){
    //   Dates[_selectedDay] = {
    //     selected: false,
    //     marked: true,
    //     dotColor: "#444",
    //     hours: [],
    //   }
    // }else{
    //   Dates[currentDate]["marked"] = !Dates[currentDate]["marked"];
    // }

    // if (currentDate == _selectedDay) {
    //   setCurrentDate(false);
    //   Dates[currentDate]["marked"] = !Dates[currentDate]["marked"];
    // } else {
    //   if (currentDate && Dates[currentDate])
    //     Dates[currentDate]["marked"] = false;
    //   else {
    //     Dates[_selectedDay] = {
    //       selected: false,
    //       marked: true,
    //       dotColor: "#444",
    //       hours: [],
    //     };
    //   }

    //   if (Dates[_selectedDay]) Dates[_selectedDay]["marked"] = true;

    // }
    // setDates(Dates);
    setCurrentDate(_selectedDay);
    let SelectedDay = {};
    SelectedDay[_selectedDay] = {
      selected: true,
      marked: true,
      dotColor: "#444",
      selectedColor: "#FFF",
    };
    setSelectedDay(SelectedDay);

    setSelectedHour(false);
  };

  useEffect(() => {
    CalendarService.getCalendar(currentMonth).then((res) => {
      let datesList = {};
      if (res) {
        setAvailableHours({ ...res, ...availableHours });
        //console.log(availableHours);

        Object.keys(res).forEach((key) => {
          let dt =
            key.substring(6) +
            "-" +
            key.substring(3, 5) +
            "-" +
            key.substring(0, 2);
          //console.log(dt);
          if (res[key].length >= 24) {
            datesList[dateFormat(new Date(dt), "yyyy-mm-dd")] = {
              disabled: true,
              disableTouchEvent: true,
            };
          }
        });
      }

      setDates(datesList);
    });
  }, [currentMonth]);

  const today = new Date();

  // Create a new Date object for tomorrow by adding one day
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 7);

  return (
    <CalendarWrap>
      <CalendarLayout className="callayout">
        <Box className="calbox">
          <Calendar
            // Handler which gets executed on day press. Default = undefined
            onDayPress={(day) => onDaySelect(day)}
            monthFormat={"MMMM, yyyy"}
            // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday
            firstDay={1}
            // Enable the option to swipe between months. Default = false
            enableSwipeMonths={true}
            minDate={dateFormat(tomorrow, "yyyy-mm-dd")}
            markedDates={{ ...dates, ...selectedDay }}
            selected={currentDate}
            renderArrow={(direction) => <Arrow dir={direction} />}
            onMonthChange={(month) => {
              setCurrentMonth(month.dateString);
            }}
          />
        </Box>
        <Box className="cre-list">
          <Box component="span">Les créneaux disponibles</Box>
          <Box component="ul" className="dates-list">
            {currentDate &&
              hours &&
              hours.map((item, index) => {
                return (
                  <Box
                    onClick={() => {
                      if (
                        !availableHours[
                          dateFormat(new Date(currentDate), "dd/mm/yyyy")
                        ]?.includes(item)
                      ) {
                        setSelectedHour(item);
                      }
                    }}
                    className={classNames({
                      active:
                        selectedHour == item &&
                        !availableHours[
                          dateFormat(new Date(currentDate), "dd/mm/yyyy")
                        ]?.includes(item),
                    })}
                    key={"hours-" + index}
                    component="li"
                    style={
                      availableHours[
                        dateFormat(new Date(currentDate), "dd/mm/yyyy")
                      ]?.includes(item)
                        ? { cursor: "no-drop", opacity: 0.3 }
                        : { curson: "pointer" }
                    }
                    disabled={
                      dates[currentDate]?.hours?.includes(item) ? true : false
                    }
                  >
                    {item}
                  </Box>
                );
              })}
          </Box>
        </Box>
      </CalendarLayout>
      <Box textAlign={"right"} marginTop={"30px"}>
        {loading ? (
          <CircularProgress />
        ) : (
          currentDate &&
          selectedHour && (
            <SignButton
              handleClick={() => onHourSelected(currentDate, selectedHour)}
            >
              Confirmer
            </SignButton>
          )
        )}
      </Box>
    </CalendarWrap>
  );
}

const Arrow = (props) => {
  return <Box className={props.dir}></Box>;
};
