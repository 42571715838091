import { useTheme } from "@emotion/react";
import { DeleteOutline, DriveFolderUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useState } from "react";
import PrestationService from "../../../store/apis/PrestationApi";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Row = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  maxWidth: 1010,
  margin: "auto",
  flexWrap: "wrap",
  width: "100%",
};

const ImagesList = ({ dialog, onClose, demande }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [listFiles, setListFiles] = useState(demande.images);
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
    handleFileUpload(event.target.files);
  };
  const handleDeleteFile = (index, id) => {
    setSelectedFile(index);
    setIsLoadingDelete(true);
    try {
      PrestationService.deleteImages(id).then((response) => {
        console.log(id, index, selectedFiles);

        const newlistFiles = listFiles.filter((_, i) => i !== index);
        setListFiles(newlistFiles);
        setIsLoadingDelete(false);
        setSelectedFile(null);
      });
    } catch (error) {
      console.error("Error deleting files:", error);
      //alert("Failed to delete files");
    }
  };

  const handleFileUpload = async (files) => {
    setError(false);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("demande", demande?.id);
    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    if (files.length > 0) {
      try {
        PrestationService.addImages(formData)
          .then((response) => {
            if (response.code === "ERR_NETWORK") setError(true);
            if (response.images) setListFiles(response.images);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error uploading files:", error);
            setError(true);
            setIsLoading(false);
          });
      } catch (error) {
        console.error("Error uploading files:", error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleFileDelete = async (id) => {};

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 600, maxWidth: { md: 600, lg: 600 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Photos client
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItem={"center"}
          justifyContent={"space-between"}
          rowGap={2}
          flexWrap={"wrap"}
          paddingBottom={"20px"}
        >
          <Box flexBasis={{ xs: "100%", md: "100%" }}>
            <Box mt={0.75} mb={3}>
              <Box>
                {listFiles.length === 0 && (
                  <Typography
                    variant="body1"
                    fontSize={{ xs: 16, md: 24 }}
                    textAlign={"center"}
                  >
                    Aucune photo télécharger
                  </Typography>
                )}
                {listFiles.map((file, key) => {
                  return (
                    <div
                      key={key}
                      style={{ float: "left", marginRight: "10px" }}
                      className="image-preview"
                    >
                      <p style={{ margin: "5px" }}>
                        <a href={file.file} target="_blank">
                          <img
                            src={file.file}
                            alt={file.file}
                            style={{
                              verticalAlign: "middle",
                              border: "1px solid #F2E208",
                              maxWidth: "100px",
                              height: "80px",
                            }}
                          />
                        </a>
                        <br />
                        <Button
                          startIcon={
                            isLoadingDelete && key === selectedFile ? (
                              <CircularProgress
                                color="inherit"
                                width="20px"
                                height="20px"
                                sx={{
                                  width: "20px !important",
                                  height: "20px !important",
                                }}
                              />
                            ) : (
                              <DeleteOutline />
                            )
                          }
                          variant={"text"}
                          sx={{
                            fontWeight: { xs: 700 },
                            fontSize: { xs: 16 },
                            fontFamily: { xs: "Bauhaus" },
                            textTransform: "capitalize",
                          }}
                          color="inherit"
                          onClick={() => handleDeleteFile(key, file.id)}
                        >
                          Supprimer
                        </Button>
                      </p>
                    </div>
                  );
                })}
              </Box>
            </Box>
            <Box sx={Row}>
              <Box width={"100%"}>
                <Box
                  sx={{
                    border: "1px solid #002c3a",
                    padding: "8px 15px",
                    width: "100%",
                    borderRadius: "5px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  htmlFor="file-input-after"
                >
                  <input
                    id="file-input-after"
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />

                  <label
                    htmlFor="file-input-after"
                    className="custom-file-upload"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        color="inherit"
                        width="27px"
                        height="27px"
                        sx={{
                          width: "36px !important",
                          height: "36px !important",
                          display: "inline-block",
                          marginBottom: "-7px",
                        }}
                      />
                    ) : (
                      <DriveFolderUpload
                        fontSize="large"
                        sx={{
                          cursor: "pointer",
                          marginBottom: "-7px",
                        }}
                      />
                    )}{" "}
                    Télécharger
                  </label>
                </Box>
                {error && (
                  <Typography
                    fontSize={{
                      xs: 14,
                      textAlign: "center",
                    }}
                    color="red"
                  >
                    Il y a eu une erreur lors du téléchargement de l'image.
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default ImagesList;
