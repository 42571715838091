import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import logo from "../../../assets/images/logos/light_logo_lg.png";
import { styled, ThemeProvider } from "@mui/system";
import theme from "../../../assets/theme";
import styles from "../../../assets/css/style.js";
import { StyleSheet } from "react-native";
import btnbg from "../../../assets/images/btnbg.png";
import { TextInput } from "react-native-web";
import { Download, KeyboardBackspaceOutlined } from "@mui/icons-material";
import moment from "moment";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import { formatNumberWithLeadingZeros } from "../../../config/utils.js";
moment.locale("fr");

const PdfWrap = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 390,
  fontSize: 14,
  lineHeight: "20px",
  color: "#fff",
  width: "100%",
  border: "1px solid #03EBFF",
  backgroundColor: "rgba(0, 0, 0, .69)",
  padding: "20px 30px",
  marginRight: -5,
  marginLeft: -5,

  ".paddingRight40": {
    paddingRight: 40,
  },
  ".infos": {
    maxWidth: "max-content",
    margin: "0 0 0 auto",
  },
  ".fz18": {
    fontSize: 18,
  },
  ".details": {
    margin: "30px 0 20px",

    ".dt1": {
      width: "56%",
    },
    ".dt2": {
      width: "44%",
    },
  },
  ".h2": {
    marginBottom: 10,
  },
  ".prodlist": {
    borderSpacing: 0,

    tr: {
      td: {
        padding: 6,

        "&:not(:nth-of-type(2))": {
          textAlign: "center",
        },
      },
      "&:first-of-type": {
        fontSize: 16,
        lineHeight: "23px",
      },
      "&:not(:first-of-type)": {
        border: "1px solid #03EBFF",
        td: {
          border: "1px solid #03EBFF",
          verticalAlign: "top",
        },
      },
    },
  },
  ".paiment": {
    margin: "0 -30px",
    padding: "10px 30px 30px",
    borderBottom: "1px solid #03EBFF",
  },
  ".signature": {
    padding: "10px 0",
    img: {
      display: "block",
      margin: "auto",
    },
  },
  ".textBfrBtns": {
    display: "flex",
    marginBottom: 20,

    "> div:first-of-type": {
      flex: 1,
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "0 -20px",
      width: "auto",

      "> div:last-child": {
        display: "none",
      },
    },
  },
  "._btssp": {
    display: "flex",

    "&.disabled": {
      opacity: 0.3,
    },
    "> div:first-of-type": {
      flex: 1,
    },
    "> div:last-child": {
      width: 250,
    },
    "._blcsntr": {
      height: "100%",
      display: "flex",
      border: "2px dashed #04ebff",
      marginLeft: 10,
      alignItems: "center",
      justifyContent: "center",
      fontSize: 18,
      lineHeight: "22px",
      fontFamily: theme.typography.fontFamilyBauhaus,
      color: "#04ebff",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "10px -20px 0",
      width: "auto",

      ">div:last-child": {
        width: "100%",
      },
      "._blcsntr": {
        minHeight: 100,
        margin: "13px 0 0 0",
      },
    },
  },
  ".btn": {
    height: 44,
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #03EBFF",

    "&.style1": {
      backgroundImage:
        "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
      textTransform: "uppercase",
      borderWidth: 2,
      marginBottom: 10,
    },
  },
  ".txt": {
    fontSize: 12,
    lineHeight: "17px",
    margin: "20px 0",
  },
  ".ftr": {
    margin: "auto",
    maxWidth: 270,
    fontSize: 9,
    lineHeight: "13px",
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    ".fz18": {
      fontSize: 14,
    },
    ".head": {
      "> tbody > tr": {
        display: "flex",
        flexDirection: "column",

        img: {
          width: 105,
        },
      },
    },
    ".infos": {
      margin: 0,
      marginTop: "15px",

      td: {
        textAlign: "left",

        "&:last-child": {
          textAlign: "center",
        },
      },
    },
    ".paiment": {
      padding: "10px 10px 10px",

      "> table > tbody > tr": {
        display: "flex",
        flexDirection: "column-reverse",

        "> td": {
          marginBottom: 15,

          "&:last-child": {
            maxWidth: 170,
            width: "100%",
            margin: "0 0 0 auto",
          },
          "table td:first-of-type": {
            whiteSpace: "nowrap",
            verticalAlign: "text-bottom",
          },
          "table td": {
            paddingTop: 4,
          },
        },
      },
    },
    ".details": {
      marginBottom: 5,

      "> tbody > tr": {
        display: "flex",
        flexDirection: "column",

        "> td": {
          marginBottom: 15,
        },
      },
      ".dt1, .dt2": {
        width: "100%",

        td: {
          width: "50%",
        },
      },
    },
    ".prodlist ": {
      margin: "15px -31px 0",
      width: "calc(100% + 62px)",

      tr: {
        "&:first-of-type": {
          fontSize: 12,
        },
      },
      ".tgl": {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "&:before": {
          content: "attr(data-label-sm)",
          fontSize: 14,
          fontWeight: "bold",
          fontFamily: theme.typography.fontFamilyBauhaus,
          color: "#000",
        },
        "&:after": {
          content: '""',
          border: "6px solid transparent",
          borderTopColor: theme.palette.primary.main,
          borderBottom: "none",
          marginLeft: 6,
        },
        "> div": {
          position: "absolute",
          top: "100%",
          left: 0,
          width: 200,
          backgroundColor: "#fff",
          padding: 4,
          zIndex: 100,
          border: "1px solid #ccc",
          borderRadius: 4,
          opacity: 0,
          visibility: "hidden",
        },
        "&:hover": {
          "> div": {
            opacity: 1,
            visibility: "visible",
          },
        },
      },
    },
    ".signature": {
      margin: "0 -20px",
      width: "auto",

      "> tbody > tr": {
        display: "flex",
        flexWrap: "wrap",

        td: {
          width: "50%",
          textAlign: "center",

          "&:first-of-type": {
            width: "100%",
            marginBottom: 15,
            textAlign: "left",
          },
        },
      },
    },
    ".txt": {
      margin: "20px -20px",
    },
  },
}));
const Table = styled("table")(({ theme }) => ({
  width: "100%",
  ".b": {
    fontWeight: "bold",
  },
  ".ffbauhaus": {
    fontFamily: theme.typography.fontFamilyBauhaus,
  },
}));
const Tbody = styled("tbody")(({ theme }) => ({}));
const Tr = styled("tr")(({ theme, color }) => ({
  color: color ? color : "inherit",
}));
const Td = styled("td")(({ theme }) => ({
  "&.vab": {
    verticalAlign: "bottom",
  },
}));
const Img = styled("img")(({ theme }) => ({}));
const Link = styled("a")(({ theme }) => ({}));
const Strong = styled("strong")(({ theme }) => ({}));
const Card = styled("div")(({ theme, color, minHeight }) => ({
  color: color ? color : "inherit",
  "&.card": {
    border: "1px solid rgba(62, 195, 236, 0.6)",
    padding: "10px 25px",
    minHeight: minHeight ? minHeight : 126,
  },
  [theme.breakpoints.down("sm")]: {
    "&.card": {
      padding: "10px 16px",
    },
  },
}));
const Buttons = styled("div")(({ theme }) => ({
  textAlign: "right",
  maxWidth: 725,
  marginTop: 20,

  a: {
    height: 43,
    lineHeight: "43px",
    backgroundImage: "url(" + btnbg + ")",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    fontSize: 16,
    textAlign: "center",
    padding: "0 25px",
    display: "inline-block",
    fontFamily: theme.typography.fontFamilyBauhaus,
    marginLeft: 15,

    img: {
      marginRight: 15,
      verticalAlign: "middle",
    },
  },
}));

export default function OT({ presta, goBack, type }) {
  const { user } = useSelector((state) => state.userAuth);
  let userData = user.user?.data ? user.user?.data : user.data;

  const [detailsOrder, setDetailsOrder] = useState(presta.order_ot);
  const [loading, setLoading] = useState(false);

  const downloadPdf = () => {
    setLoading(true);
    const pdfOptions = {
      margin: 10,
      filename: "OD-" + formatNumberWithLeadingZeros(presta?.id) + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    const element = document.getElementById("pdf-container"); // Replace 'pdf-container' with the ID of the container holding your HTML content

    html2pdf(element, pdfOptions);

    setLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box>
          <Box
            display={"flex"}
            justifyContent="space-between"
            marginBottom={"6px"}
          >
            <Box>
              <Button
                startIcon={<KeyboardBackspaceOutlined />}
                variant={"text"}
                color={"primary"}
                sx={{ fontWeight: 700, textTransform: "capitalize" }}
                onClick={goBack}
              >
                Retour
              </Button>
            </Box>
            <Box
              fontSize="16px"
              fontFamily={theme.typography.fontFamilyBauhaus}
            >
              <Button
                startIcon={<Download />}
                variant={"text"}
                color={"primary"}
                sx={{ fontWeight: 700, textTransform: "capitalize" }}
                onClick={downloadPdf}
              >
                {loading ? <CircularProgress size="25px" /> : "Télécharger"}
              </Button>
            </Box>
          </Box>

          <div>
            <div id="pdf-container">
              <PdfWrap className="pdf" style={StyleSheet.flatten(styles.pdf)}>
                <Table className="head">
                  <Tbody>
                    <Tr>
                      <Td>
                        <Img src={logo} />
                      </Td>
                      <Td>
                        <Table className="infos">
                          <Tbody>
                            <Tr className="b fz18">
                              <Td align="right" className="paddingRight40">
                                Order de travail
                              </Td>
                              <Td>
                                OT{formatNumberWithLeadingZeros(presta?.id)}
                              </Td>
                            </Tr>
                            <Tr className="b fz18">
                              <Td align="right" className="paddingRight40">
                                Date envoi OT :
                              </Td>
                              <Td>
                                {moment
                                  .parseZone(presta?.client?.date_inscription)
                                  .format("DD/MM/YYYY")}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>

                <Table className="details">
                  <Tbody>
                    <Tr>
                      <Td className="dt2">
                        <Table>
                          <Tbody>
                            <Tr>
                              <Td width="50%">
                                Commentaires des traveaux à réaliser
                              </Td>
                              <Td>{presta?.nature_probleme}</Td>
                            </Tr>
                            <Tr>
                              <Td width="50%">Nom client final</Td>
                              <Td>
                                {presta?.client ? (
                                  <>
                                    <Strong>{`${presta?.client?.prenom} ${presta?.client?.nom}`}</Strong>
                                  </>
                                ) : (
                                  <TextInput
                                    type="text"
                                    style={{
                                      height: 25,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      border: "1px solid #fff",
                                    }}
                                    placeholder="Saisissez le nom ici"
                                  />
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>Adresse</Td>
                              <Td>{presta?.client?.adresse}</Td>
                            </Tr>
                            <Tr>
                              <Td>Code Postal</Td>
                              <Td>{presta?.client?.code_postal} </Td>
                            </Tr>
                            <Tr>
                              <Td>Ville</Td>
                              <Td>{presta?.client?.ville} </Td>
                            </Tr>
                            <Tr>
                              <Td>Contact</Td>
                              <Td>{presta?.client?.telephone}</Td>
                            </Tr>
                            <Tr>
                              <Td>E-mail</Td>
                              <Td>{presta?.client?.email}</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>

                <Typography variant="subtitle1">Notes</Typography>
                <Table>
                  <Tbody>
                    {detailsOrder.map((detailOrder, index) => {
                      return (
                        <Tr>
                          <Td className="td b fz18 ffbauhaus">
                            {detailOrder?.user?.nom} {detailOrder?.user?.prenom}
                          </Td>
                          <Td>
                            <Box className="tgl" data-label-sm="DIAGNOSTIC">
                              <Box>
                                {detailOrder?.titre}
                                <br></br>
                                {moment
                                  .parseZone(detailOrder?.date_debut)
                                  .format("DD/MM/YYYY")}{" "}
                                à{" "}
                                {moment
                                  .parseZone(detailOrder?.date_debut)
                                  .format("HH:mm")}
                              </Box>
                            </Box>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </PdfWrap>
            </div>
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
