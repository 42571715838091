import { View } from "react-native-web";
import { useTheme } from "@emotion/react";
import { NavBar, Footer } from "../../../components";
import { Box, Typography, Container, Dialog, Slide, useMediaQuery } from "@mui/material";
import styles from "../../../assets/css/style";
import { StyleSheet } from "react-native";
import MyAssistance from "./tabs/MyAssistance";
import ServiceProgress from "./tabs/ServiceProgress";
import TabPanel from "../../../components/TabPanel";
import { useSelector } from "react-redux";
import { forwardRef, useEffect, useState } from "react";
import PrestationService from "../../../store/apis/PrestationApi";
import Setting from "../home/sections/SettingSection";


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dashboard() {
  const { user } = useSelector((state) => state.userAuth);
  let userData = user.user?.data ? user.user?.data : user.data;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [datas, setDatas] = useState(null);
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetchData();
  }, [userData]);

  const refresh = () => {
    setDatas();
    fetchData();
  };

  const fetchData = () => {
    setLoad(true);
    if (userData?.id) {
      PrestationService.detailPrestation(userData?.id)
        .then((res) => {
          setDatas(res);
          setLoad(false);
        })
        .catch((e) => {
          setLoad(false);
          setError(e);
        });
    } else {
      setLoad(false);
      console.log("erreur");
    }
  };

  const items = [
    {
      label: "Déroulement de la prestation",
      content: <ServiceProgress datas={datas} load={load} refresh={refresh} />,
    },
    /*  {
      label: "Mes documents",
      content: <MyDocuments datas={datas} />,
    }, */
    {
      label: "Mon assistance",
      content: <MyAssistance />,
    },
  ];

  const [dialog, setDialog] = useState(false);
  const handleDialog = () => {
    //if (dialog) dispatch(reset());
    setDialog(!dialog);
  };

  return (
    <View style={StyleSheet.flatten(styles.body)}>
      <NavBar handleDialog={handleDialog} />
      <Box style={StyleSheet.flatten(styles.bgWrap)}>
        <Typography
          style={StyleSheet.flatten(styles.dottedLine)}
          align="center"
          variant="h3"
        >
          Dashboard
        </Typography>
        <Container maxWidth="lg">
          <TabPanel items={items} />
        </Container>
      </Box>

      <Footer />
      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        fullScreen={!isMd}
        PaperProps={{
          sx: {
            maxWidth: { md: 670 },
            width: { md: 670 },
            borderRadius: { md: 4 },
            boxShadow: "none",
          },
        }}
        onClose={() => {
          dispatch(reset());
          setDialog(!dialog);
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: theme.palette.background.backdropModal,
          },
        }}
      >
        <Setting handleDialog={handleDialog} />
      </Dialog>
    </View>
  );
}
