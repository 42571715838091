import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import logo from "../../../../assets/images/fibme-logo.png";
import { styled, ThemeProvider } from "@mui/system";
import theme from "../../../../assets/theme/index.js";
import styles from "../../../../assets/css/style.js";
import { StyleSheet } from "react-native";
import signature from "../../../../assets/images/signature.png";
import timesIcon from "../../../../assets/images/times.png";
import trueIcon from "../../../../assets/images/true.png";
import btnbg from "../../../../assets/images/btnbg.png";
import { ValidationButton } from "../../../../components/buttons/index.js";
import moment from "moment";
import { Button, TextInput } from "react-native-web";
import { KeyboardBackspaceOutlined } from "@mui/icons-material";
import PrestationService from "../../../../store/apis/PrestationApi.js";
import {
  document_type_devis,
  document_type_fiche_intervention,
  STATUS_REJECTED,
} from "../../../../config/keys.js";
moment.locale("fr");

const PdfWrap = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 390,
  fontSize: 14,
  lineHeight: "20px",
  color: "#002C3A",
  maxWidth: 725,
  border: "1px solid #03EBFF",
  backgroundColor: "#fff",
  padding: "20px 30px",
  marginRight: -5,
  marginLeft: -5,

  ".paddingRight40": {
    paddingRight: 40,
  },
  ".infos": {
    maxWidth: "max-content",
    margin: "0 0 0 auto",
  },
  ".fz18": {
    fontSize: 18,
  },
  ".details": {
    margin: "30px 0 20px",

    ".dt1": {
      width: "56%",
    },
    ".dt2": {
      width: "44%",
    },
  },
  ".h2": {
    marginBottom: 10,
  },
  ".prodlist": {
    borderSpacing: 0,

    tr: {
      td: {
        padding: 6,

        "&:not(:nth-of-type(2))": {
          textAlign: "center",
        },
      },
      "&:first-child": {
        fontSize: 16,
        lineHeight: "23px",
      },
      "&:not(:first-child)": {
        border: "1px solid #03EBFF",
        td: {
          border: "1px solid #03EBFF",
          verticalAlign: "top",
        },
      },
    },
  },
  ".paiment": {
    margin: "0 -30px",
    padding: "10px 30px 30px",
    borderBottom: "1px solid #03EBFF",
  },
  ".signature": {
    padding: "10px 0",
    img: {
      display: "block",
      margin: "auto",
    },
  },
  ".textBfrBtns": {
    display: "flex",
    marginBottom: 20,
    "> div:first-child": {
      flex: 1,
    },
    "> div:last-child": {
      width: 250,
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "0 -20px",
      width: "auto",

      "> div:last-child": {
        display: "none",
      },
    },
  },
  "._btssp": {
    display: "flex",

    "> div:first-child": {
      flex: 1,
    },
    "> div:last-child": {
      width: 250,
    },
    "._blcsntr": {
      height: "100%",
      display: "flex",
      border: "2px dashed #04ebff",
      marginLeft: 10,
      alignItems: "center",
      justifyContent: "center",
      fontSize: 18,
      lineHeight: "22px",
      fontFamily: theme.typography.fontFamilyBauhaus,
      color: "#04ebff",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "10px -20px 0",
      width: "auto",

      ">div:last-child": {
        width: "100%",
      },
      "._blcsntr": {
        minHeight: 100,
        margin: "13px 0 0 0",
      },
    },
  },
  ".btn": {
    height: 44,
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #03EBFF",

    "&.style1": {
      backgroundImage:
        "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
      textTransform: "uppercase",
      borderWidth: 2,
      marginBottom: 10,
    },
  },
  ".txt": {
    fontSize: 12,
    lineHeight: "17px",
    margin: "20px 0",
  },
  ".ftr": {
    margin: "auto",
    maxWidth: 270,
    fontSize: 9,
    lineHeight: "13px",
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    ".fz18": {
      fontSize: 14,
    },
    ".head": {
      "> tbody > tr": {
        display: "flex",
        flexDirection: "column",

        img: {
          width: 105,
        },
      },
    },
    ".infos": {
      margin: 0,
      marginTop: "15px",

      td: {
        textAlign: "left",

        "&:last-child": {
          textAlign: "center",
        },
      },
    },
    ".paiment": {
      padding: "10px 10px 10px",

      "> table > tbody > tr": {
        display: "flex",
        flexDirection: "column-reverse",

        "> td": {
          marginBottom: 15,

          "&:last-child": {
            maxWidth: 170,
            width: "100%",
            margin: "0 0 0 auto",
          },
          "table td:first-child": {
            whiteSpace: "nowrap",
            verticalAlign: "text-bottom",
          },
          "table td": {
            paddingTop: 4,
          },
        },
      },
    },
    ".details": {
      marginBottom: 5,

      "> tbody > tr": {
        display: "flex",
        flexDirection: "column",

        "> td": {
          marginBottom: 15,
        },
      },
      ".dt1, .dt2": {
        width: "100%",

        td: {
          width: "50%",
        },
      },
    },
    ".prodlist ": {
      margin: "15px -31px 0",
      width: "calc(100% + 62px)",

      tr: {
        "&:first-child": {
          fontSize: 12,
        },
      },
      ".tgl": {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "&:before": {
          content: "attr(data-label-sm)",
          fontSize: 14,
          fontWeight: "bold",
          fontFamily: theme.typography.fontFamilyBauhaus,
          color: "#000",
        },
        "&:after": {
          content: '""',
          border: "6px solid transparent",
          borderTopColor: theme.palette.primary.main,
          borderBottom: "none",
          marginLeft: 6,
        },
        "> div": {
          position: "absolute",
          top: "100%",
          left: 0,
          width: 200,
          backgroundColor: "#fff",
          padding: 4,
          zIndex: 100,
          border: "1px solid #ccc",
          borderRadius: 4,
          opacity: 0,
          visibility: "hidden",
        },
        "&:hover": {
          "> div": {
            opacity: 1,
            visibility: "visible",
          },
        },
      },
    },
    ".signature": {
      margin: "0 -20px",
      width: "auto",

      "> tbody > tr": {
        display: "flex",
        flexWrap: "wrap",

        td: {
          width: "50%",
          textAlign: "center",

          "&:first-child": {
            width: "100%",
            marginBottom: 15,
            textAlign: "left",
          },
        },
      },
    },
    ".txt": {
      margin: "20px -20px",
    },
  },
}));
const Table = styled("table")(({ theme }) => ({
  width: "100%",
  ".b": {
    fontWeight: "bold",
  },
  ".ffbauhaus": {
    fontFamily: theme.typography.fontFamilyBauhaus,
  },
}));
const Tbody = styled("tbody")(({ theme }) => ({}));
const Tr = styled("tr")(({ theme }) => ({}));
const Td = styled("td")(({ theme }) => ({}));
const Img = styled("img")(({ theme }) => ({}));
const Link = styled("a")(({ theme }) => ({}));
const Strong = styled("strong")(({ theme }) => ({}));
const Card = styled("div")(({ theme }) => ({
  "&.card": {
    border: "1px solid rgba(62, 195, 236, 0.6)",
    padding: "10px 25px",
    minHeight: 40,
    marginBottom: 20,
  },
  [theme.breakpoints.down("sm")]: {
    "&.card": {
      padding: "10px 16px",
    },
  },
}));
const Buttons = styled("div")(({ theme }) => ({
  textAlign: "right",
  maxWidth: 725,
  marginTop: 20,

  a: {
    height: 43,
    lineHeight: "43px",
    backgroundImage: "url(" + btnbg + ")",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    fontSize: 16,
    textAlign: "center",
    padding: "0 25px",
    display: "inline-block",
    fontFamily: theme.typography.fontFamilyBauhaus,
    marginLeft: 15,

    img: {
      marginRight: 15,
      verticalAlign: "middle",
    },
  },
}));

export default function PDFFicheIntervention({
  presta,
  client,
  type,
  handleSigned,
}) {
  const [detailDevis, setDetailDevis] = useState(
    presta.documents.find(
      (obj) => obj.type.toLowerCase() === document_type_fiche_intervention
    )
  );
  const [subServices, setSubServices] = useState(
    presta?.service?.length > 0 ? presta?.service : []
  );
  const [date, setDate] = useState(moment.parseZone(presta?.date_prestation));
  const [duree, setDuree] = useState(presta?.duree_intervention);
  const [heureDebut, setHeureDebut] = useState(presta?.debut_intervention);
  const [heureFin, setHeureFin] = useState(presta?.fin_intervention);
  const [imageAfters, setImageAfters] = useState(presta?.image_afters);
  const [imageBefores, setImageBefores] = useState(presta?.image_befores);
  const [loading, setLoading] = useState(false);
  const [signed, setSigned] = useState(
    detailDevis.signature_client !== "signed" ? false : true
  );

  const rejectDevis = () => {
    setLoading(true);

    let data = {
      status: STATUS_REJECTED,
    };

    PrestationService.editDocument(detailDevis?.id, data).then((response) => {
      setLoading(false);
      console.log(response);
    });
  };

  const validateDevis = () => {
    if (!signed) return;
    setLoading(true);

    let data = {
      status: "signed",
      signatureClient: "signed",
      dateSignatureClient: new Date(),
    };

    PrestationService.editDocument(detailDevis?.id, data).then((response) => {
      setLoading(false);
      handleSigned();
      console.log(response);
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <PdfWrap className="pdf" style={StyleSheet.flatten(styles.pdf)}>
          <Table className="head">
            <Tbody>
              <Tr>
                <Td>
                  <Img src={logo} />
                </Td>
                <Td>
                  <Table className="infos">
                    <Tbody>
                      <Tr className="b fz18">
                        <Td align="right" className="paddingRight40">
                          Fiche d’intervention N°
                        </Td>
                        <Td>{detailDevis.id}</Td>
                      </Tr>
                      <Tr className="b fz18">
                        <Td align="right" className="paddingRight40">
                          Date° :
                        </Td>
                        <Td>
                          {moment
                            .parseZone(detailDevis.date)
                            .format("DD/MM/YYYY")}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Table className="details">
            <Tbody>
              <Tr>
                <Td className="dt1">
                  <Table>
                    <Tbody>
                      <Tr>
                        <Td>Société</Td>
                        <Td>
                          <Strong>Fibme</Strong>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Siège social</Td>
                        <Td>18 Place Marcel Rebuffat</Td>
                      </Tr>
                      <Tr>
                        <Td>Code Postal</Td>
                        <Td>91140 </Td>
                      </Tr>
                      <Tr>
                        <Td>Ville</Td>
                        <Td>VILLEJUST </Td>
                      </Tr>
                      <Tr>
                        <Td>Téléphone</Td>
                        <Td>01 64 47 52 49</Td>
                      </Tr>
                      <Tr>
                        <Td>E-mail</Td>
                        <Td>contact@fibme.com</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Td>
                <Td className="dt2">
                  <Table>
                    <Tbody>
                      <Tr>
                        <Td width="50%">Client</Td>
                        <Td>
                          <Strong>{`${client?.prenom} ${client?.nom}`}</Strong>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Adresse</Td>
                        <Td>{client?.adresse}</Td>
                      </Tr>
                      <Tr>
                        <Td>Code Postal</Td>
                        <Td>{client?.code_postal} </Td>
                      </Tr>
                      <Tr>
                        <Td>Ville</Td>
                        <Td>{client?.ville} </Td>
                      </Tr>
                      <Tr>
                        <Td>Téléphone</Td>
                        <Td>{client?.telephone}</Td>
                      </Tr>
                      <Tr>
                        <Td>E-mail</Td>
                        <Td>{client?.email}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Box
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent="space-between"
          >
            <Box width={"100%"}>
              <Table className="details">
                <Tbody>
                  <Tr>
                    <Td className="dt1">
                      <Table>
                        <Tbody>
                          <Tr>
                            <Td>
                              <Strong className="fz18">Intervenant(s)</Strong>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Strong>{`${presta?.prestataire?.prenom} ${presta?.prestataire?.nom}`}</Strong>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Td>
                    <Td className="dt2">
                      <Table>
                        <Tbody>
                          <Tr>
                            <Td align="right" width="50%">
                              <Strong>Date</Strong>
                            </Td>
                            <Td>
                              {/* <DatePicker defaultValue={date.format("DD/MM/YYYY")} /> */}
                              <Strong>
                                <TextInput
                                  type="text"
                                  style={{
                                    height: 25,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                  }}
                                  value={date.format("DD/MM/YYYY")}
                                  disabled
                                />
                              </Strong>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td align="right">
                              <Strong>Durée</Strong>
                            </Td>
                            <Td>
                              <TextInput
                                type="text"
                                style={{
                                  height: 25,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                                value={duree}
                                disabled
                              />
                            </Td>
                          </Tr>
                          <Tr>
                            <Td align="right">
                              <Strong>Début</Strong>
                            </Td>
                            <Td>
                              <TextInput
                                type="text"
                                style={{
                                  height: 25,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                                value={heureDebut}
                                disabled
                              />
                            </Td>
                          </Tr>
                          <Tr>
                            <Td align="right">
                              <Strong>Fin</Strong>
                            </Td>
                            <Td>
                              <TextInput
                                type="text"
                                style={{
                                  height: 25,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                }}
                                value={heureFin}
                                disabled
                              />
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>

          <Typography variant="h2" className="h2">
            Nature du problème
          </Typography>
          <Card className="card" color={theme.palette.secondary.main}>
            {client?.problemes.map((x) => `${x}`)}
          </Card>

          <Typography variant="h2" className="h2">
            Détail
          </Typography>
          <Card className="card" color={theme.palette.secondary.main}>
            {presta?.detail}
          </Card>

          <Table className="prodlist">
            <Tbody>
              <Tr>
                <Td width="90">Réf</Td>
                <Td>Déscriptions</Td>
                <Td width="70">Qté</Td>
              </Tr>
              {subServices.map((subService, index) => {
                return (
                  <Tr color={theme.palette.secondary.main} key={index}>
                    <Td className="td b fz18 ffbauhaus">
                      {subService?.service && subService?.service?.id !== 0
                        ? "#" + subService?.service?.id
                        : "--"}
                    </Td>
                    <Td>
                      <Box className="tgl" data-label-sm="DIAGNOSTIC">
                        <Box>
                          {subService?.service && subService?.service?.id !== 0
                            ? subService?.service?.libelle
                            : subService?.nom_service}
                          <br />
                          {subService?.service && subService?.service?.id !== 0
                            ? subService?.service?.description
                            : ""}
                        </Box>
                      </Box>
                    </Td>
                    <Td>
                      <Strong>{subService?.quantity}</Strong>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Box
            fontFamily={theme.typography.fontFamilyBauhaus}
            display={"flex"}
            marginTop="50px"
          >
            <Box
              flex="1"
              display={"flex"}
              alignItems="center"
              marginRight={"15px"}
              justifyContent={"center"}
            >
              <Box marginRight={"10px"} color={"#F2E208"}>
                Photos avant la réalisation
              </Box>
            </Box>
            <Box
              flex="1"
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Box marginRight={"10px"} color={"#F2E208"}>
                Photos après la réalisation
              </Box>
            </Box>
          </Box>
          <Box
            fontFamily={theme.typography.fontFamilyBauhaus}
            display={"flex"}
            marginTop="10px"
          >
            <Box
              flex="1"
              display={"flex"}
              alignItems="start"
              marginRight={"15px"}
              justifyContent={"center"}
            >
              <div className="image-preview-container">
                {imageBefores &&
                  imageBefores.map((file, key) => {
                    return (
                      <div key={key} className="image-preview">
                        <p>
                          <a href={file.file} target="_blank">
                            <img
                              src={file.file}
                              alt={file.file}
                              width={"80px"}
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #F2E208",
                              }}
                            />
                          </a>
                          {/* <br />
                            <Button
                              startIcon={<DeleteOutline />}
                              variant={"text"}
                              sx={{
                                fontWeight: { xs: 700 },
                                fontSize: { xs: 16 },
                                fontFamily: { xs: "Bauhaus" },
                                textTransform: "capitalize",
                              }}
                              color="inherit"
                              onClick={() => handleDeleteFileBefore(key)}
                            >
                              Supprimer
                            </Button> */}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </Box>
            <Box
              flex="1"
              display={"flex"}
              alignItems="start"
              justifyContent={"center"}
            >
              <div className="image-preview-container">
                {imageAfters &&
                  imageAfters.map((file, key) => {
                    return (
                      <div key={key} className="image-preview">
                        <p>
                          <a href={file.file} target="_blank">
                            <img
                              src={file.file}
                              alt={file.file}
                              width={"80px"}
                              style={{
                                verticalAlign: "middle",
                                border: "1px solid #F2E208",
                              }}
                            />
                          </a>
                          {/* <br />
                            <Button
                              startIcon={<DeleteOutline />}
                              variant={"text"}
                              sx={{
                                fontWeight: { xs: 700 },
                                fontSize: { xs: 16 },
                                fontFamily: { xs: "Bauhaus" },
                                textTransform: "capitalize",
                              }}
                              color="inherit"
                              onClick={() => handleDeleteFileBefore(key)}
                            >
                              Supprimer
                            </Button> */}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </Box>
          </Box>

          <Table className="signature">
            <Tbody>
              <Tr>
                <Td className="fz18">
                  Signé le{" "}
                  {moment.parseZone(detailDevis.date).format("DD MMMM YYYY")}
                </Td>
                {/* <Td width="33.33%">
                  <Img src={signature} />
                </Td>
                <Td width="33.33%">
                  <Strong className="fz18">Craig Korsgaard</Strong>
                  <Box>Chef d’affaire</Box>
                </Td> */}
              </Tr>
            </Tbody>
          </Table>
          <Box className="_btssp">
            <Box>
              <Link className="btn style1">BON POUR ACCORD</Link>
              <Link className="btn"> {moment().format("DD MMMM YYYY")}</Link>
            </Box>
            <Box>
              <Box className="_blcsntr" onClick={() => setSigned(true)} sx={{cursor:"pointer"}}>
                {signed ? "Signer" : "Cliquer ici pour signer"}
              </Box>
            </Box>
          </Box>
          <Box className="txt">
            Règlement comptant sans escompte. Le non respect du délai de
            paiement expose le client, après mise en demeure, à des pénalité de
            retard ainsi qu’à une indemnité forfaitaire pour frais de
            recouvrement de 40,00€
          </Box>
          <Box className="ftr">
            SASU au capital de 30 000,00 € - RCS EVRY 901 827 931 - TVA
            intracommunautaire FR 16804898500 - APE 6190Z
          </Box>
        </PdfWrap>
        {(!signed || parseInt(presta.etape) === 9) && (
          <>
            {loading ? (
              <Box textAlign={"right"} marginTop={"30px"}>
                <CircularProgress />
              </Box>
            ) : (
              <Buttons>
                <ValidationButton handleClick={rejectDevis} icon>
                  Refuser
                </ValidationButton>

                <ValidationButton
                  handleClick={validateDevis}
                  disabled={signed ? false : true}
                >
                  Signer
                </ValidationButton>
              </Buttons>
            )}
          </>
        )}
      </ThemeProvider>
    </>
  );
}
