import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import Select from "react-select";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  hoursList,
  rdv_type_call,
  rdv_type_prestation,
} from "../../../config/keys";
import CalendarService from "../../../store/apis/CalendarApi";
import dateFormat from "dateformat";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object({});

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const EditRdvForm = ({
  dialog,
  onClose,
  demande,
  datePlanification,
  setDatePlanification,
  dateAppel,
  setDateAppel,
  setLoadPresta,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedCreneau, setSelectedCreneau] = useState();
  const [typeRdv, setTypeRdv] = useState(
    parseInt(demande.etape) === 1 ? rdv_type_call : rdv_type_prestation
  );
  const [typeRdvTitre, setTypeRdvTitre] = useState(
    parseInt(demande.etape) === 1
      ? "Appel téléphonique"
      : "Intervention de la prestation"
  );

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    setLoading(true);

    /* console.log(newData, demande, calendar);
    return; */
    let newDate = {
      date_debut: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
      date_fin: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
      date_prevu: null,
    };

    if (!dateAppel && !datePlanification) {
      const obj = {
        user: `/api/users/${demande.client.id}`,
        dateDebut: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
        dateFin: selectedDate + "T" + selectedCreneau.value + ":00.120Z",
        titre: "Rdv",
        type: typeRdv,
        status: true,
      };

      CalendarService.addCalendar(obj)
        .then((res) => {
          parseInt(demande.etape) === 1
            ? setDateAppel(res)
            : setDatePlanification(res);
          setLoading(false);
          setLoadPresta();
          onClose();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      return;
    }

    let calnedarId =
      parseInt(demande.etape) === 1 ? dateAppel.id : datePlanification.id;

    CalendarService.editCalendar(calnedarId, newDate)
      .then((res) => {
        parseInt(demande.etape) === 1
          ? setDateAppel(res)
          : setDatePlanification(res);
        setLoading(false);
        setLoadPresta();
        onClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChangeDate = (event) => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
  };

  const handleChangeCreneau = (creneau) => {
    setSelectedCreneau(creneau);
  };

  useEffect(() => {
    setSelectedDate(
      parseInt(demande.etape) === 1
        ? dateAppel?.date_prevu
          ? dateAppel?.date_prevu.split("T")[0]
          : dateAppel?.date_debut.split("T")[0]
        : datePlanification?.date_prevu
        ? datePlanification?.date_prevu.split("T")[0]
        : datePlanification?.date_debut.split("T")[0]
    );

    setSelectedCreneau(
      parseInt(demande.etape) === 1
        ? {
            value: dateAppel?.date_prevu
              ? dateAppel?.date_prevu
                  .split("T")[1]
                  .split("+")[0]
                  .substring(0, 5)
              : dateAppel?.date_debut
                  .split("T")[1]
                  .split("+")[0]
                  .substring(0, 5),
            label: dateAppel?.date_prevu
              ? dateAppel?.date_prevu
                  .split("T")[1]
                  .split("+")[0]
                  .substring(0, 5)
              : dateAppel?.date_debut
                  .split("T")[1]
                  .split("+")[0]
                  .substring(0, 5),
          }
        : {
            value: datePlanification?.date_prevu
              ? datePlanification?.date_prevu
                  .split("T")[1]
                  .split("+")[0]
                  .substring(0, 5)
              : datePlanification?.date_debut
                  .split("T")[1]
                  .split("+")[0]
                  .substring(0, 5),
            label: datePlanification?.date_prevu
              ? datePlanification?.date_prevu
                  .split("T")[1]
                  .split("+")[0]
                  .substring(0, 5)
              : datePlanification?.date_debut
                  .split("T")[1]
                  .split("+")[0]
                  .substring(0, 5),
          }
    );
    
  }, [demande.etape]);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 600, maxWidth: { md: 600, lg: 600 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Modifier le rdv
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItem={"center"}
            justifyContent={"space-between"}
            rowGap={2}
            flexWrap={"wrap"}
          >
            <Box flex={{ xs: "0 1 100%", md: "0 1 50%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Selectionner la date
                </Typography>
                <Box>
                  <input
                    type="date"
                    name="date-planification"
                    id="date-planification"
                    lang="fr_FR"
                    locale="fr_FR"
                    min={dateFormat(today, "yyyy-mm-dd")}
                    style={{
                      color: "#ddd",
                      fontSize: "24px",
                      border: "1px solid #3EC3EC",
                      borderRadius: "3px",
                      padding: "10px",
                    }}
                    value={selectedDate}
                    onChange={handleChangeDate}
                  />
                </Box>
              </Box>
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 50%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Selectionner le créneau
                </Typography>
                <Box>
                  <Controller
                    name={"service"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={selectedCreneau}
                        styles={customStyles}
                        placeholder={"Créneau"}
                        options={hoursList}
                        onChange={handleChangeCreneau}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </form>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton type={"red"} handleClick={onClose}>
                Annuler
              </CustomButton>
              {selectedDate && selectedCreneau && (
                <CustomButton
                  type={"yellow"}
                  textColor={"white"}
                  handleClick={handleSubmit(onSubmit)}
                >
                  Modifier
                </CustomButton>
              )}
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default EditRdvForm;
