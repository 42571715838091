import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import DTable from "../../../components/DTable";
import filterIcon from "../../../assets/images/sliders.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fontSize, styled } from "@mui/system";
import { Image, TextInput } from "react-native-web";
import classNames from "classnames";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Import tools
import Search from "../Tools/Search";
import { HeaderTab, TabItems, TabItem, Filter } from "../Tools/Tab";
import Title from "../Tools/Title";
import GoBack from "../Tools/BoBack";
import moment from "moment";
import {
  document_type_devis,
  document_type_fiche_intervention,
  document_type_order_travail,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SIGNED,
} from "../../../config/keys";
import DetailsDemande from "../Benefits/DetailsDemande";
import OT from "../Quotes/OT";
import PDFFile from "./PDFFile";
import PDF from "../Quotes/PDF";

const Recent = {
  head: [
    {
      label: "N°",
      width: 14.66,
    },
    {
      label: "Date",
      width: 15.66,
    },
    {
      label: "Client",
      width: 16.66,
    },
    {
      label: "Adresse",
      width: 19.66,
    },
    {
      label: "Intervenant",
      width: 16.66,
    },
    {
      label: false,
      width: 16.66,
    },
  ],
  body: [
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "En attente de signature",
        },
      ],
    },
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "En attente de signature",
        },
      ],
    },
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "En attente de signature",
        },
      ],
    },
  ],
};
const Signed = {
  head: [
    {
      label: "N°",
      width: 14.66,
    },
    {
      label: "Date",
      width: 15.66,
    },
    {
      label: "Client",
      width: 16.66,
    },
    {
      label: "Adresse",
      width: 19.66,
    },
    {
      label: "Intervenant",
      width: 16.66,
    },
    {
      label: false,
      width: 16.66,
    },
  ],
  body: [
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "",
          icon: <CheckCircleIcon />,
        },
      ],
    },
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "",
          icon: <CheckCircleIcon />,
        },
      ],
    },
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "",
          icon: <CheckCircleIcon />,
        },
      ],
    },
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "",
          icon: <CheckCircleIcon />,
        },
      ],
    },
  ],
};
const refused = {
  head: [
    {
      label: "N°",
      width: 14.66,
    },
    {
      label: "Date",
      width: 15.66,
    },
    {
      label: "Client",
      width: 16.66,
    },
    {
      label: "Adresse",
      width: 19.66,
    },
    {
      label: "Intervenant",
      width: 16.66,
    },
    {
      label: false,
      width: 16.66,
    },
  ],
  body: [
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "",
          icon: <HighlightOffIcon />,
          colorIcon: "#FF5252",
        },
      ],
    },
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "",
          icon: <HighlightOffIcon />,
          colorIcon: "#FF5252",
        },
      ],
    },
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "",
          icon: <HighlightOffIcon />,
          colorIcon: "#FF5252",
        },
      ],
    },
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "",
          icon: <HighlightOffIcon />,
          colorIcon: "#FF5252",
        },
      ],
    },
    {
      columns: [
        {
          type: "text",
          text: "123",
        },
        {
          type: "text",
          text: "15/07/2021",
        },
        {
          type: "text",
          text: "Terry Stanton",
        },
        {
          type: "text",
          text: "35 rue du Saint Lazar, Asnières sur Seine, 94100",
        },
        {
          type: "text",
          text: "Alexandre Quentin",
        },
        {
          type: "status",
          color: "#F4EB7C",
          text: "",
          icon: <HighlightOffIcon />,
          colorIcon: "#FF5252",
        },
      ],
    },
  ],
};

const HEAD = [
  {
    label: "N°",
    width: 10.66,
  },
  {
    label: "Date",
    width: 13.66,
  },
  {
    label: "Client",
    width: 16.66,
  },
  {
    label: "Adresse",
    width: 19.66,
  },
  {
    label: "Intervenant",
    width: 16.66,
  },
  {
    label: false,
    width: 26.66,
  },
  {
    label: false,
    width: 12.66,
  },
  {
    label: false,
    width: 16,
  },
];

export default function InterventionSheets({
  goBack,
  data,
  page,
  setPage,
  status,
  setStatus,
  loadingDevis,
  showPagination,
  currentTab,
  setCurrentTab,
}) {
  const [search, setSearch] = useState("");
  const [allData, setAllData] = useState(false);

  let AllData = {
    isList: false,
    head: HEAD,
    body: [],
  };

  useEffect(() => {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const El = data[i];
        var row = [
          {
            type: "text",
            text: El.id,
          },
          {
            type: "text",
            text: moment.parseZone(El.date).format("DD/MM/YYYY"),
          },
          {
            type: "text",
            text:
              El.prestation?.client?.nom + " " + El.prestation?.client?.prenom,
          },
          {
            type: "text",
            text:
              El.prestation?.client?.adresse +
              ", " +
              El.prestation?.client?.code_postal,
          },
          {
            type: "text",
            text:
              El.prestation?.prestataire?.nom +
              " " +
              El.prestation?.prestataire?.prenom,
          },
          {
            type: "status",
            color: "#F4EB7C",
            text:
              El.status === STATUS_PENDING
                ? "En attente de signature"
                : El.status === STATUS_SIGNED
                ? "Signée"
                : "Refusée",
          },
          {
            type: "text",
            text:
              El.status === STATUS_PENDING ? (
                <AccessTimeIcon />
              ) : El.status === STATUS_SIGNED ? (
                <CheckCircleIcon />
              ) : (
                <HighlightOffIcon />
              ),
            colorIcon: "#F4EB7C",
          },
          {
            type: "more",
            text: "Voir info",
            onClick: () => onClickDetail(El.prestation),
          },
        ];
        page > 1
          ? allData.body.push({ columns: row })
          : AllData.body.push({ columns: row });
      }
      page > 1 ? setAllData(allData) : setAllData(AllData);
    }
  }, [data]);

  const [detail, setDetail] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [detailPresta, setDetailPresta] = useState({});
  const [showDocument, setShowDocument] = useState(false);
  const [typeDocument, setTypeDocument] = useState(document_type_devis);

  const onClickDetail = (demande) => {
    console.log(demande);
    setDetail(demande);
    //setDetailPresta(demande);
    setShowDetail(true);
  };
  const onClickBackDetail = (demande) => {
    setDetail({});
    setDetailPresta({});
    setShowDetail(false);
  };

  const createDevis = () => {};

  const handleShowDocument = (type) => {
    setTypeDocument(type);
    setShowDetail(false);
    setShowDocument(true);
    /*  if (type === document_type_devis) {
      setShowDetail(false);
      setShowDocuemnt(true);
    }
    if (type === document_type_fiche_intervention) {
      setShowDetail(false);
      setShowDocuemnt(true);
    } */
  };

  const goDetail = () => {
    //setShowCreateDevis(false);
    setShowDetail(true);
    setShowDocument(false);
    //setShowEditPartenaire(false);
  };

  if (showDetail) {
    return (
      <DetailsDemande
        presta={detail}
        goBack={onClickBackDetail}
        createDevis={createDevis}
        editPartenaire={createDevis}
        showDocument={handleShowDocument}
        setDetailPresta={setDetailPresta}
        detailPresta={detailPresta}
      />
    );
  } else if (showDocument) {
    if (typeDocument === document_type_devis)
      return (
        <PDF
          presta={detailPresta}
          goBack={goDetail}
          type={typeDocument}
          setDetailPresta={() => {}}
        />
      );
    if (typeDocument === document_type_fiche_intervention)
      return (
        <PDFFile
          presta={detailPresta}
          goBack={goDetail}
          type={typeDocument}
          setDetailPresta={() => {}}
        />
      );
    if (typeDocument === document_type_order_travail)
      return <OT presta={detailPresta} goBack={goDetail} type={typeDocument} />;
  } else {
    return (
      <Box>
        <GoBack onClick={goBack}>
          <ArrowBackIcon /> Retour
        </GoBack>
        <HeaderTab className="header-tab" sx={{margin: '20px 0'}}>
          <Title>Fiches d’intervention</Title>
          <Filter>
            <TabItems>
              <TabItem
                sx={{ cursor: "pointer" }}
                className={classNames({ hovered: currentTab == 1 })}
                onClick={() => {
                  setPage(1);
                  if (allData) allData.body = [];
                  setCurrentTab(1);
                  setStatus(STATUS_PENDING);
                }}
              >
                Récentes
              </TabItem>
              <TabItem
                sx={{ cursor: "pointer" }}
                className={classNames({ hovered: currentTab == 2 })}
                onClick={() => {
                  setPage(1);
                  if (allData) allData.body = [];
                  setCurrentTab(2);
                  setStatus(STATUS_SIGNED);
                }}
              >
                Signées
              </TabItem>
              <TabItem
                sx={{ cursor: "pointer" }}
                className={classNames({ hovered: currentTab == 3 })}
                onClick={() => {
                  setPage(1);
                  if (allData) allData.body = [];
                  setCurrentTab(3);
                  setStatus(STATUS_REJECTED);
                }}
              >
                Refusées
              </TabItem>
            </TabItems>
           {/*  <Search className={"searchinput"}>
              <TextInput type="search" placeholder="Recherche" />
            </Search>
            <Box className="filterIcon">
              <Image source={filterIcon} style={{ width: 30, height: 30 }} />
            </Box> */}
          </Filter>
        </HeaderTab>

        {currentTab == 1 && (
          <DTable
            data={allData}
            keyprop={STATUS_PENDING}
            load={loadingDevis}
            showPagination={showPagination}
            setPage={setPage}
            page={page}
          />
        )}
        {currentTab == 2 && (
          <DTable
            data={allData}
            keyprop={STATUS_SIGNED}
            load={loadingDevis}
            showPagination={showPagination}
            setPage={setPage}
            page={page}
          />
        )}
        {currentTab == 3 && (
          <DTable
            data={allData}
            keyprop={STATUS_REJECTED}
            load={loadingDevis}
            showPagination={showPagination}
            setPage={setPage}
            page={page}
          />
        )}
      </Box>
    );
  }
}
