import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Container,
  Tooltip,
} from "@mui/material";
import logo from "../../assets/images/logos/light_logo_lg.png";
import menuBtn from "../../assets/images/menu_btn.png";
import activeMenuBtn from "../../assets/images/active_menu_btn.png";

import user from "../../assets/images/icons/user.png";

import { DragHandleRounded } from "@mui/icons-material";
import { AccountButton, ButtonWithPopUp } from "../buttons";
import { useState } from "react";

const ResponsiveAppBar = ({ handleDialog, callendarClick }) => {
  const [active, setActive] = useState(false);
  return (
    <AppBar
      position="sticky"
      //color="transparent"
      elevation={0}
      sx={{
        backdropFilter: "blur(5px)",
        padding: { xs: "12px 0", lg: "24px 0" },
        backgroundColor: '#000',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              mr: 2,
              flexGrow: { xs: 1, lg: 0 },
              display: "flex",
              width: { xs: 100, md: 128, lg: 164 },
              height: { xs: 31, md: 40, lg: 50 },
            }}
          >
            <img src={logo} alt="logo" />
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "flex", lg: "none" },
              alignItems: "center",
            }}
          >
            <Box>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <DragHandleRounded fontSize="large" />
              </IconButton>
            </Box>

            <Box margin={{ md: "0 16px" }}>
              <Tooltip title="Open settings">
                <IconButton color="inherit" onClick={handleDialog}>
                  <img src={user} alt="account icon"  />
                </IconButton>
              </Tooltip>
            </Box>

            <Box
              sx={{ display: { xs: "none", md: "flex", lg: "none" } }}
              ml={1}
            >
              <IconButton color="inherit" onClick={() => setActive(!active)}>
                <img
                  src={active ? activeMenuBtn : menuBtn}
                  alt="menu btn icom"
                />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex" },
              alignItems: "center",
              position: "relative",
            }}
          ></Box>
          <Box
            sx={{
              flexGrow: { md: 0.5, lg: 0.75 },
              display: { xs: "none", md: "block" },
            }}
          />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <ButtonWithPopUp handleClick={callendarClick} />
          </Box>
          <Box sx={{ display: { xs: "none", lg: "flex" } }} ml={4}>
            <IconButton color="inherit" onClick={() => setActive(!active)}>
              <img src={active ? activeMenuBtn : menuBtn} alt="menu btn icom" />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "none", lg: "block" } }} ml={4}>
            <AccountButton handleClick={handleDialog} admin={true} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
