import { forwardRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import signinBg from "../../../../assets/images/signin_bg.png";
import { styled } from "@mui/system";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useTheme } from "@emotion/react";
import { RegisterHeading } from "../../../../components/headings";
import { EmailInput, PasswordInput } from "../../../../components/inputs";
import { userLogin, userLogout } from "../../../../store/slices/userAuthReducer";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigation } from "@react-navigation/native";

const schema = yup.object({
  email: yup
    .string()
    .email("Entrer un email valide")
    .required("L'email est requis"),
  password: yup.string().required("Le mot de passe est requis"),
});

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  backgroundColor: "#fff",
  backgroundImage: `url(${signinBg})`,
  backgroundSize: "100%",
  backgroundPosition: "bottom center",
  padding: theme.spacing(4, 1),
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("md")]: {
    backgroundImage: "none",
    padding: theme.spacing(2, 0),
  },
}));

export default function Setting({ handleDialog }) {
  const navigation = useNavigation();
  const theme = useTheme();
  const { user } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const tableau = () => {
    if (
      user.data?.role == "ROLE_CLIENT" ||
      user.user?.data?.role === "ROLE_CLIENT"
    ) {
      navigation.navigate("Dashboard");
    } else if (
      user.data?.role == "ROLE_ADMIN" ||
      user.data?.role == "ROLE_SUPER_ADMIN" ||
      user.user?.data?.role === "ROLE_ADMIN" ||
      user.user?.data?.role === "ROLE_SUPER_ADMIN"
    ) {
      navigation.navigate("AdminDashboard");
    } else if (
      user.data?.role == "ROLE_ACTIVITE" ||
      user.user?.data?.role === "ROLE_ACTIVITE"
    ) {
      navigation.navigate("ActivityDashboard");
    } else if (
      user.data?.role == "ROLE_FACTURATION" ||
      user.user?.data?.role === "ROLE_FACTURATION"
    ) {
      navigation.navigate("FacturationDashboard");
    } else if (
      user.data?.role == "ROLE_PRESTATAIRE" ||
      user.user?.data?.role === "ROLE_PRESTATAIRE"
    ) {
      navigation.navigate("PartnerDashboard");
    } else {
      navigation.navigate("Home");
    }
    handleDialog();
  };

  const logout = () => {
    dispatch(userLogout());
    //setActive(false);
    navigation.navigate("Home");
    handleDialog();
  };

  const profile = () => {
    navigation.navigate("PartnerProfile");
    handleDialog();
  };

  return (
    <>
      <BoxContainer>
        <Container maxWidth={isMd ? "sm" : "xs"}>
          <Box display={"flex"} flexDirection={{ xs: "column" }}>
            <Box component={"span"} alignSelf={{ xs: "center", md: "end" }}>
              <IconButton
                aria-label="close"
                size="large"
                onClick={handleDialog}
                sx={{
                  "& .closeIcon": {
                    fontSize: "30px",
                    color: "black",
                    transition: "all 0.5s ease-in-out",
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.closeButton,
                    "& .closeIcon": {
                      transform: "rotate(-90deg)",
                    },
                  },
                }}
              >
                <CloseRounded fontSize="large" className="closeIcon" />
              </IconButton>
            </Box>
            <Box mb={2} mt={{ md: -5 }}>
              <RegisterHeading>paramètres</RegisterHeading>
            </Box>
          </Box>
          <Box paddingLeft={{ md: 5 }} paddingRight={{ md: 5 }}>
            <Box
              display={"flex"}
              mb={{ xs: 4, md: 6 }}
              mt={4}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CustomButton handleClick={tableau}>Tableau de bord</CustomButton>
            </Box>
            {(user?.data?.role === "ROLE_PRESTATAIRE" ||
              user?.user?.data?.role === "ROLE_PRESTATAIRE") && (
              <Box
                display={"flex"}
                mb={{ xs: 4, md: 6 }}
                mt={4}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CustomButton
                  handleClick={profile}
                >
                  Mon compte
                </CustomButton>
              </Box>
            )}
            <Box
              display={"flex"}
              mb={{ xs: 4, md: 6 }}
              mt={4}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CustomButton type={"red"} handleClick={logout}>
                Déconnexion
              </CustomButton>
            </Box>
            {/* <Typography
              variant={"body2"}
              textTransform={"uppercase"}
              fontWeight={400}
              fontSize={{ xs: 16 }}
              textAlign={"center"}
              mb={3}
            >
              Vous n’avez pas encore de compte ?{" "}
              <Typography
                variant={"body2"}
                component={"a"}
                color={"primary"}
                fontWeight={700}
                fontSize={{ xs: 16 }}
                /*sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleDialog();
                  navigation.navigate("Register");
                }}
                sx={[
                  {
                    cursor: "pointer",
                    position: "relative",
                    "&::after": {
                      content: `""`,
                      width: "100%",
                      height: "1px",
                      backgroundColor: "transparent",
                      position: "absolute",
                      bottom: -10,
                      left: 0,
                      //display: "none",
                      transition: "all .5s ease-in-out",
                    },
                    "&:hover": {
                      "&::after": {
                        bottom: 0,
                        backgroundColor: theme.palette.primary.main,
                      },
                    },
                  },
                ]}
              >
                Incrivez-vous !
              </Typography>
            </Typography> */}
          </Box>
        </Container>
      </BoxContainer>
    </>
  );
}
