import {
  Box,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import React, { forwardRef, useState } from "react";
import { ScrollView } from "react-native-web";
import styles from "../../assets/css/style";
import { PartnerNavBar } from "../../components";
import TabPanel from "../../components/TabPanel";
import Benefits from "./Benefits";
import bgimg from "../../assets/images/black-bg-dots.png";
import { useSelector } from "react-redux";
import DocumentsNeed from "./Documents/DocumentsNeed";
import Profile from "./Profile/Profile";
import RdvsCalendar from "./CallsCalendar/RdvsCalendar";
import { StyleSheet } from "react-native";
import Setting from "../customer/home/sections/SettingSection";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PartnerLayout(page = "dashboard") {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const { user } = useSelector((state) => state.userAuth);
  const [needDocument, setNeedDocument] = useState(
    user?.data?.status === 2 || user?.user?.data?.status === 2 ? true : false
  );
  const [activePage, setActivePage] = useState(page);
  const items = [
    {
      label: "Prestations",
      content: <Benefits />,
    },
    /* {
      label: "Partenaires",
      content: <Partners />,
    },
    {
      label: "clients",
      content: <Customer />,
    },
    {
      label: "Admins",
      content: <Admins />,
    },
    {
      label: "créer un devis",
      content: <Quotes />,
    }, */
    /* {
      label: "documents",
      content: <Documents />,
    }, */
  ];

  const [dialog, setDialog] = useState(false);
  const handleDialog = () => {
    //if (dialog) dispatch(reset());
    setDialog(!dialog);
  };

  return (
    <ScrollView
      style={{
        backgroundImage: "url(" + bgimg + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <PartnerNavBar
        callendarClick={() => setActivePage("calls")}
        handleDialog={handleDialog}
      />

      <Box style={StyleSheet.flatten(styles.bgWrap)}>
        <Typography
          color={"#fff"}
          style={StyleSheet.flatten(styles.dottedLine)}
          align="center"
          variant="h3"
        >
          {needDocument ? (
            <>Documents obligatoires</>
          ) : (
            (() => {
              switch (activePage) {
                case "dashboard":
                  return "Dashboard";

                case "profile":
                  return "Mon profile";

                case "calls":
                  return "Calendrier des rendez-vous";
              }
            })()
          )}
        </Typography>
        <Container maxWidth="lg">
          {needDocument ? (
            <DocumentsNeed />
          ) : (
            (() => {
              switch (activePage) {
                case "dashboard":
                  return <TabPanel type="style2" items={items} />;

                case "calls":
                  return <RdvsCalendar setActivePage={setActivePage} />;

                /*  case "documents":
                  return <TabPanel type="style2" items={items} />; */

                case "profile":
                  return <Profile type="style2" items={items} />;
              }
            })()
          )}
        </Container>
      </Box>
      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        fullScreen={!isMd}
        PaperProps={{
          sx: {
            maxWidth: { md: 670 },
            width: { md: 670 },
            borderRadius: { md: 4 },
            boxShadow: "none",
          },
        }}
        onClose={() => {
          dispatch(reset());
          setDialog(!dialog);
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: theme.palette.background.backdropModal,
          },
        }}
      >
        <Setting handleDialog={handleDialog} />
      </Dialog>
    </ScrollView>
  );
}
