import { useTheme } from "@emotion/react";
import {
  Box,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImagesList = ({ dialog, onClose, demande }) => {
  console.log(demande.images);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 600, maxWidth: { md: 600, lg: 600 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            Photos client
          </Typography>
        </Box>
        <Box
          display={"flex"}
          alignItem={"center"}
          justifyContent={"space-between"}
          rowGap={2}
          flexWrap={"wrap"}
        >
          <Box flexBasis={{ xs: "100%", md: "100%" }}>
            <Box mt={0.75} mb={3} sx={{ float: "left" }}>
              <Box>
                {demande.images.map((file, key) => {
                  return (
                    <div
                      key={key}
                      style={{ float: "left", marginRight: "10px" }}
                      className="image-preview"
                    >
                      <p style={{ margin: "5px" }}>
                        <a href={file.file} target="_blank">
                          <img
                            src={file.file}
                            alt={file.file}
                            style={{
                              verticalAlign: "middle",
                              border: "1px solid #F2E208",
                              maxWidth: "100px",
                              height: "80px",
                            }}
                          />
                        </a>
                      </p>
                    </div>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default ImagesList;
