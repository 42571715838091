import { useTheme } from "@emotion/react";
import { Box, Button, Dialog, Slide, useMediaQuery } from "@mui/material";
import React, { forwardRef } from "react";
import { Text, TouchableOpacity, View } from "react-native-web";
import moment from "moment";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} unmountOnExit />;
});

export default function CallClient({
  dialog,
  onClose,
  selectedItems,
  selectedDate,
}) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  // Remove duplicates by type, date_debut, and demandes_client.id
  const removeDuplicates = (data) => {
    const seen = new Set();
    return data.filter((item) => {
      const key = `${item.detail.type}-${item.detail.date_debut}-${item.detail.user.demandes_client[0].id}`;
      if (seen.has(key)) {
        return false;
      } else {
        seen.add(key);
        return true;
      }
    });
  };

  const sortedAndFilteredData = removeDuplicates(selectedItems).sort(
    (a, b) => new Date(a.detail.date_debut) - new Date(b.detail.date_debut)
  );


  return (
    <Dialog
      open={dialog}
      /* TransitionComponent={Transition} */
      animationType="fade"
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 300 } }}
      onClose={onClose}
    >
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text style={{ padding: 20 }}>
          <b>
            Rendez-vous du{" "}
            {moment.parseZone(selectedDate).format("DD MMM YYYY")}
          </b>
        </Text>
        <View
          style={{ padding: 20, backgroundColor: "white", borderRadius: 10 }}
        >
          {selectedItems.length === 0 && (
            <View style={{ marginBottom: 10, textAlign: "center" }}>
              <Text>----------------------------------------</Text>
              <Text>Aucun rendez-vous pour aujourd’hui</Text>
              <Text>----------------------------------------</Text>
            </View>
          )}
          {sortedAndFilteredData.map((item, index) => (
            <View key={index} style={{ marginBottom: 10, width: 300  }}>
              <Text>
                <b>Client :</b> {item.name}
              </Text>
              <Text>
                <b>Téléphone :</b> {item.telephone}
              </Text>
              <Text>
                <b>Heure de l'intervention :</b> {item.heure}
              </Text>
              <Text>
                <b>Référence :</b>{" "}
                {item.detail.user.demandes_client[0]?.reference
                  ? item.detail.user.demandes_client[0]?.reference
                  : item.detail.user.demandes_client[0]?.id}
              </Text>
              <Text>
                <b>Type de prestation :</b>{" "}
                {item.detail.user.demandes_client[0]?.nature_probleme}
              </Text>
              <Text>
                <b>Adresse :</b> {item.detail.user.adresse}
              </Text>
              {item.detail?.user?.demandes_client[0]?.status_partenaire && (
                <Text>
                  <b>État de la prestation :</b>{" "}
                  {item.detail.user.demandes_client[0]?.status_partenaire}
                </Text>
              )}{" "}
              <Box sx={{ textAlign: "center" }}>---------</Box>
            </View>
          ))}
        </View>
        <Button
          variant={"text"}
          color={"primary"}
          sx={{ fontWeight: 700, textTransform: "capitalize" }}
          onClick={onClose}
        >
          Fermer
        </Button>
        {/* <TouchableOpacity style={{ padding: 20 }} onPress={onClose}>
          <Text>Fermer</Text>
        </TouchableOpacity> */}
      </View>
    </Dialog>
  );
}
