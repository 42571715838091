import axios from "axios";
import authHeader, { authHeaderDataForm, authHeaderExport } from "./authHeader";
import {
  base_url_api,
  status_prestation_a_venir,
  status_prestation_en_cours,
  status_prestation_terminer,
  status_prestation_urgent,
} from "../../config/keys";

const listPrestation = async (page, filtre, searchValue = "", detailFilter) => {
  let prestFilter = "";
  switch (filtre) {
    case status_prestation_urgent:
      prestFilter = "&urgent=1";
      break;
    case status_prestation_en_cours:
      prestFilter =
        "&etape[]=1&etape[]=2&etape[]=3&etape[]=4&etape[]=5&etape[]=6&etape[]=7&etape[]=8&etape[]=9&etape[]=10";
      break;
    case status_prestation_terminer:
      prestFilter = "&etape[]=11&etape[]=12";
      break;
    default:
    // code block
  }
  try {
    const response = await axios.get(
      `${base_url_api}/demandes?page=${page}&order[id]=desc${prestFilter}&search=${searchValue}${detailFilter}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const listPrestationPartenaire = async (page, partnerId, filtre, searchValue = "") => {
  let prestFilter = "";
  switch (filtre) {
    case status_prestation_a_venir:
      prestFilter = "&etape[]=1&etape[]=2&etape[]=3&etape[]=4&etape[]=5";
      break;
    case status_prestation_en_cours:
      prestFilter = "&etape[]=6&etape[]=7&etape[]=8&etape[]=9&etape[]=10";
      break;
    case status_prestation_terminer:
      prestFilter = "&etape[]=11&etape[]=12";
      break;
    default:
    // code block
  }
  try {
    const response = await axios.get(
      `${base_url_api}/demandes?page=${page}&type[]=Planifier&type[]=Attribuer&order[id]=desc${prestFilter}&prestataire=${partnerId}&search=${searchValue}`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const detailPrestation = async (clientId) => {
  try {
    const response = await axios.get(
      `${base_url_api}/demandes?client=${clientId}`,
      {
        headers: authHeader(),
      }
    );
    return response.data[0];
  } catch (error) {
    return error;
  }
};

const getPrestation = async (id) => {
  try {
    const response = await axios.get(`${base_url_api}/demandes/${id}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const addPrestation = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/demandes`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const addPrestationAdmin = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/demandes`, data, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const editPrestation = async (id, data) => {
  try {
    const response = await axios.put(
      `${base_url_api}/demandes/${id}`,
      {
        ...data,
      },
      { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const getListDocuments = async (page = 1, type, status) => {
  try {
    const response = await axios.get(`${base_url_api}/documents.jsonld?page=${page}&order[id]=desc&status=${status}&type=${type}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getDocument = async (id) => {
  try {
    const response = await axios.get(`${base_url_api}/documents/${id}.jsonld`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const addDocument = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/documents`, data, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const editDocument = async (id, data) => {
  try {
    const response = await axios.put(
      `${base_url_api}/documents/${id}`,
      {
        ...data,
      },
      { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const addServices = async (data) => {
  try {
    const response = await axios.post(
      `${base_url_api}/demande_services`,
      data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const removeServices = async (data) => {
  try {
    const response = await axios.delete(
      `${base_url_api}/demande_services/${data.id}`,
      { headers: authHeader() }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// const removeAdmin = async (id) => {
//   try {
//     const response = await axios.delete(`${base_url_api}/users/${id}`, {
//       headers: authHeader(),
//     });

//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

// const detailAdmin = async (id) => {
//   try {
//     const response = await axios.get(`${base_url_api}/users/${id}`, {
//       headers: authHeader(),
//     });

//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

const addImages = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/images`, data, {
      headers: authHeaderDataForm(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
const addImagesBefore = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/image_befores`, data, {
      headers: authHeaderDataForm(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
const addImagesAfter = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/image_afters`, data, {
      headers: authHeaderDataForm(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const deleteImages = async (id) => {
  try {
    const response = await axios.delete(`${base_url_api}/images/${id}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
const deleteImagesBefore = async (id) => {
  try {
    const response = await axios.delete(`${base_url_api}/image_befores/${id}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
const deleteImagesAfter = async (id) => {
  try {
    const response = await axios.delete(`${base_url_api}/image_afters/${id}`, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const exportPrestations = async (status) => {
  try {
    const response = await axios.get(`${base_url_api}/export/${status}`, {
      responseType: "arraybuffer",
      headers: authHeader(),
    });

    return response;
  } catch (error) {
    return error;
  }
};

const PrestationService = {
  detailPrestation,
  getPrestation,
  addPrestation,
  addPrestationAdmin,
  addImages,
  addImagesBefore,
  addImagesAfter,
  deleteImages,
  deleteImagesBefore,
  deleteImagesAfter,
  editPrestation,
  getListDocuments,
  getDocument,
  addDocument,
  editDocument,
  addServices,
  removeServices,
  exportPrestations,
  listPrestation,
  listPrestationPartenaire,
};

export default PrestationService;
