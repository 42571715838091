import axios from "axios";
import authHeader from "./authHeader";
import { base_url_api } from "../../config/keys";

const listClients = async (page, searchValue = "") => {
  try {
    const response = await axios.get(
      `${base_url_api}/users?page=${page}&search=${searchValue}&roles=ROLE_CLIENT&order[id]=desc`,
      { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const addClient = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/users`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const addClientAdmin = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/users`, data, {
      headers: authHeader(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const editClient = async (id, data) => {
  try {
    const response = await axios.put(
      `${base_url_api}/users/${id}`,
      {
        ...data,
      },
      { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const removeClient = async (id) => {
  try {
    const response = await axios.delete(`${base_url_api}/users/${id}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

const detailClient = async (id) => {
  try {
    const response = await axios.get(`${base_url_api}/users/${id}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

const clientsService = {
  addClient,
  addClientAdmin,
  editClient,
  removeClient,
  detailClient,
  listClients,
};

export default clientsService;
