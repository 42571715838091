import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DTable from "../../../components/DTable";
import searchIcon from "../../../assets/images/search.png";
import filterIcon from "../../../assets/images/sliders.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import moment from "moment";
import { styled } from "@mui/system";
import { Image, TextInput } from "react-native-web";
import classNames from "classnames";
import { HeaderTab, TabItems, TabItem, Filter } from "../Tools/Tab";
import Search from "../Tools/Search";
import PrestationService from "../../../store/apis/PrestationApi";
import DetailsDemande from "./DetailsDemande";
import PDF from "../Quotes/PDF";
import {
  document_type_devis,
  document_type_fiche_intervention,
  limitPagination,
  rdv_type_call,
  rdv_type_prestation,
  status_prestation_a_venir,
  status_prestation_en_cours,
  status_prestation_terminer,
  status_prestation_tout,
} from "../../../config/keys";
import PDFFile from "../Documents/PDFFile";
import { useSelector } from "react-redux";
import { Refresh } from "@mui/icons-material";

const HEAD = [
  {
    label: "réf",
    width: 5,
  },
  {
    hasDropDown: true,
    label: "Type de prestation",
    items: ["échec raccordement", "Modif. d’installation"],
    width: 25,
  },
  {
    label: "Adresse",
    width: 20,
  },
  {
    label: "Date du RDV",
    width: 20,
  },
  {
    label: false,
    width: 25,
  },
  {
    label: false,
    width: 10,
  },
  {
    label: false,
    width: 15,
  },
];

export default function Benefits() {
  const state = useSelector((state) => state.userAuth);
  const [load, setLoad] = useState(true);
  const [chargement, setChargement] = useState(false);
  const [elem, setElem] = useState();
  const [search, setSearch] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [showCreateDevis, setShowCreateDevis] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [typeDocument, setTypeDocument] = useState(document_type_devis);
  const [detail, setDetail] = useState({});
  const [filter, setFilter] = useState(status_prestation_tout);
  const [searchValue, setSearchValue] = useState("");
  const [prevFilter, setPrevFilter] = useState(status_prestation_tout);
  const [prestations, setPrestations] = useState(false);
  const [detailPresta, setDetailPresta] = useState({});

  const [page, setPage] = useState(1);
  const [showPagination, setShowPagination] = useState(false);

  const onClickDetail = (demande) => {
    setDetail(demande);
    setShowDetail(true);
  };

  const onClickAction = (demande, etape, elm) => {
    setChargement(true);
    setElem(elm);
    let data = {
      status_partenaire:
        etape === 6
          ? "commencer"
          : etape === 7
          ? "terminer"
          : etape === 11
          ? "cloturer"
          : "",
    };

    let dataDocument = {
      prestation: "/api/demandes/" + demande?.id,
      type: document_type_fiche_intervention,
    };

    PrestationService.editPrestation(demande?.id, data).then((response) => {
      if (etape === 7) {
        PrestationService.addDocument(dataDocument).then((response) => {
          setChargement(false);
        });
      } else {
        setChargement(false);
      }
      setElem();
      fetchPrestationPartner();
    });
  };

  const goBack = () => {
    setShowCreateDevis(false);
    setShowDetail(false);
    setDetail({});
    setDetailPresta({});
  };

  const goDetail = () => {
    setShowCreateDevis(false);
    setShowDetail(true);
    setShowDocument(false);
  };

  const createDevis = () => {
    setShowDetail(false);
    setShowCreateDevis(true);
    //setDetail({});
  };

  const handleShowDocument = (type) => {
    setTypeDocument(type);
    setShowDetail(false);
    setShowDocument(true);
  };

  useEffect(() => {
    if (prevFilter !== filter) {
      setPrestations(false);
      setPrevFilter(filter);
      if (page !== 1) {
        setPage(1);
      } else {
        fetchPrestationPartner();
      }
    } else {
      fetchPrestationPartner();
    }
  }, [page, filter]);

  useEffect(() => {
    setPrestations(false);
    setPrevFilter(filter);
    setPage(1);
    fetchPrestationPartner();
  }, [searchValue]);

  const fetchPrestationPartner = () => {
    setLoad(true);
    setShowPagination(false);
    let Prestations = {
      head: HEAD,
      body: [],
    };
    let UrgentPrestations = {
      head: HEAD,
      body: [],
    };
    let userId = state.user?.user
      ? state.user?.user?.data?.id
      : state.user?.data?.id;
    PrestationService.listPrestationPartenaire(
      page,
      userId,
      filter,
      searchValue
    )
      .then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            const El = res[i];
            var row = [
              {
                type: "text",
                text: El.reference ? El.reference : El.id,
                link: "",
              },
              {
                type: "text",
                text: El.nature_probleme,
                link: "",
              },
              {
                type: "text",
                text: El.client?.adresse ? El.client?.adresse : "",
              },
              {
                type: "text",
                text: (
                  <Box>
                    {El.client?.calendar?.filter(
                      (obj) => obj.type.toLowerCase() === rdv_type_prestation
                    ).length > 0 ? (
                      <>
                        {moment
                          .parseZone(
                            El.client?.calendar
                              ?.filter(
                                (obj) =>
                                  obj.type.toLowerCase() === rdv_type_prestation
                              )
                              ?.pop()?.date_debut
                          )
                          .format("DD/MM/YYYY")}
                        <br />
                        {moment
                          .parseZone(
                            El.client?.calendar
                              ?.filter(
                                (obj) =>
                                  obj.type.toLowerCase() === rdv_type_prestation
                              )
                              ?.pop()?.date_debut
                          )
                          .format("HH:mm")}
                      </>
                    ) : (
                      "---"
                    )}
                  </Box>
                ),
              },
              {
                type: "text",
                color:
                  El.status_partenaire === "terminer" ? "#7CF5FF" : "#F4EB7C",
                text: (
                  <Box component={"strong"}>
                    {/* {(El.status_partenaire === "demande" ||
                      El.status_partenaire === "confirmer") && (
                      <Typography
                        variant="caption"
                        align={"left"}
                        sx={{
                          fontSize: "20px",
                          float: "left",
                          margin: "6px 5px 0 0",
                        }}
                      >
                        <Warning />
                      </Typography>
                    )} */}
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        lineHeight: 1,
                        textTransform: "none",
                      }}
                    >
                      {parseInt(El.etape) < 6
                        ? "En attente de planification"
                        : parseInt(El.etape) === 6
                        ? "En attente de commencement"
                        : parseInt(El.etape) === 7
                        ? "Réalisation en cours"
                        : parseInt(El.etape) === 8 ||
                          parseInt(El.etape) === 9 ||
                          parseInt(El.etape) === 10
                        ? El.documents?.find(
                            (obj) =>
                              obj.type.toLowerCase() ===
                              document_type_fiche_intervention
                          )?.signature_prestataire === "signed"
                          ? "Terminer"
                          : "Terminer (Fiche d'intervention requis)"
                        : parseInt(El.etape) > 10
                        ? "Cloturer"
                        : ""}
                    </Typography>
                  </Box>
                ),
              },
              {
                type: "more",
                text: "Voir",
                onClick: () => onClickDetail(El),
              },
              {
                type: "rightButton",
                indx: i,
                text:
                  parseInt(El.etape) === 6
                    ? "Commencer"
                    : parseInt(El.etape) === 7
                    ? "Terminer"
                    : parseInt(El.etape) === 10 &&
                      El.documents?.find(
                        (obj) =>
                          obj.type.toLowerCase() ===
                          document_type_fiche_intervention
                      )?.signature_prestataire === "signed"
                    ? "Cloturer"
                    : "",
                color: El.status_partenaire === "terminer" ? false : "#F4EB7C",
                onClick: () => onClickAction(El, parseInt(El.etape), i),
              },
            ];

            if (El.urgent) {
              page > 1
                ? prestations.body.push({ icon: "danger", columns: row })
                : Prestations.body.push({ icon: "danger", columns: row });
            } else {
              page > 1
                ? prestations.body.push({ icon: "", columns: row })
                : Prestations.body.push({ icon: "", columns: row });
            }
          }

          page > 1 ? setPrestations(prestations) : setPrestations(Prestations);
          //setUrgentPrestations(UrgentPrestations);
        }
        setTimeout(() => {
          setLoad(false);
          res.length === limitPagination
            ? setShowPagination(true)
            : setShowPagination(false);
        }, 500);
      })
      .catch((error) => console.log(error));
  };

  const handleDebouncedChange = useCallback(
    _.debounce((newValue) => {
      //console.log("User finished typing:", newValue);
      setSearchValue(newValue);
      // Handle the finish typing event here
    }, 500),
    [] // Empty array ensures the debounce function is only created once
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    handleDebouncedChange(newValue);
  };

  const refresh = () => {
    setPrestations(false);
    setPrevFilter(filter);
    setPage(1);
    fetchPrestationPartner();
  };

  const onSave = (demande) => {
  
    setShowCreateDevis(false);
    setShowDetail(false);
    setDetail({});
    setDetailPresta({});

    setTimeout(() => {
      onClickDetail(demande)
      setShowDocument(false);
    }, 500);
  };

  if (showDetail) {
    return (
      <DetailsDemande
        presta={detail}
        goBack={goBack}
        createDevis={createDevis}
        showDocument={handleShowDocument}
        setDetailPresta={setDetailPresta}
        detailPresta={detailPresta}
      />
    );
  } else if (showDocument) {
    if (typeDocument === document_type_fiche_intervention)
      return (
        <PDFFile
          presta={detailPresta}
          goBack={goDetail}
          type={typeDocument}
          onSave={onSave}
        />
      );
  } else {
    return (
      <>
        <Box>
          <HeaderTab className="header-tab">
            {/* <Title>bons d’intervention</Title> */}
            <Filter>
              <TabItems>
                <TabItem
                  sx={{ cursor: "pointer" }}
                  className={classNames({
                    hovered: filter == status_prestation_tout,
                  })}
                  onClick={() => setFilter(status_prestation_tout)}
                >
                  Toutes les prestations
                </TabItem>
                <TabItem
                  sx={{ cursor: "pointer" }}
                  className={classNames({
                    hovered: filter == status_prestation_en_cours,
                  })}
                  onClick={() => setFilter(status_prestation_en_cours)}
                >
                  En cours
                </TabItem>
                <TabItem
                  sx={{ cursor: "pointer" }}
                  className={classNames({
                    hovered: filter == status_prestation_a_venir,
                  })}
                  onClick={() => setFilter(status_prestation_a_venir)}
                >
                  À venir
                </TabItem>
                <TabItem
                  sx={{ cursor: "pointer" }}
                  className={classNames({
                    hovered: filter == status_prestation_terminer,
                  })}
                  onClick={() => setFilter(status_prestation_terminer)}
                >
                  Réalisées
                </TabItem>
              </TabItems>
              <Button
                startIcon={<Refresh />}
                variant={"text"}
                color={"primary"}
                sx={{ fontWeight: 700, textTransform: "capitalize" }}
                onClick={refresh}
              >
                Rafraîchir
              </Button>
              <Search className={"searchinput"}>
                <TextInput
                  type="search"
                  placeholder="Recherche"
                  onChange={handleChange}
                />
              </Search>
              {/* <Box className="filterIcon">
                <Image source={filterIcon} style={{ width: 30, height: 30 }} />
              </Box> */}
            </Filter>
          </HeaderTab>

          <DTable
            load={load}
            showPagination={showPagination}
            setPage={setPage}
            page={page}
            data={prestations}
            keyprop={filter}
            loading={chargement}
            elem={elem}
          />
        </Box>
      </>
    );
  }
}
