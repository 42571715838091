import { Box, CircularProgress, Icon, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useState } from "react";
import theme from "../../assets/theme";
import { fontFamily, styled, ThemeProvider } from "@mui/system";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const ShowMore = styled("div")(({ theme, width }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 700,
  fontSize: 14,
  lineHeight: "16px",
  textAlign: "center",
  textTransform: "uppercase",
  color: "#03EBFF",
  marginBottom: 25,
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    position: "absolute",
    width: "100%",
  },
}));

const Table = styled("div")(({ theme }) => ({
  color: "#fff",
  fontFamily: theme.typography.fontFamilyBauhaus,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 16,
  lineHeight: "19px",
  textTransform: "capitalize",
  color: "#FFFFFF",

  [theme.breakpoints.down("sm")]: {
    minWidth: 900,
  },
}));
const Head = styled("div")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontStyle: "italic",
  fontWeight: 400,
  textAlign: "center",
  marginBottom: 5,
  /* overflow: 'auto', */
}));
const Body = styled("div")(({ theme }) => ({
  "&.islist": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    ".tr": {
      width: "calc(50% - 5px)",
    },
  },
  ".tr": {
    padding: "0 5px",
    border: "1px solid " + theme.palette.primary.main,
    marginBottom: 10,
    borderRadius: 10,
  },
  ".td": {
    minHeight: 71,
    display: "flex",
    alignItems: "center",
    padding: "5px",
    justifyContent: "center",

    ".link": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    ".status": {
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
    },
    ".more, .updelete": {
      display: "inline-block",
      padding: "5px 10px",
      border: "1px dashed #03EBFF",
      borderRadius: 4,
      cursor: "pointer",
    },
    ".rightIcon": {
      verticalAlign: "middle",
      marginLeft: 6,
    },
    ".rightButton": {
      verticalAlign: "middle",
      marginLeft: 6,
      display: "inline-block",
      padding: "5px 10px",
      border: "1px solid #03EBFF",
      borderRadius: 4,
      cursor: "pointer",
    },
    ".updelete": {
      width: "calc(50% - 5px)",
      margin: 0,
      "&.up": {
        borderColor: theme.palette.secondary.main,
      },
      "&.de": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));
const Tr = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",

  ".icontr": {
    position: "absolute",
    zIndex: 20,
    right: -14,
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: 30,
  },
  "&.tr-two-cols": {
    width: "calc(50% - 5px)",
  },
  ".menudots": {
    position: "relative",
    "&:hover": {
      ul: {
        opacity: 1,
        visibility: "visible",
      },
      svg: {
        transform: "rotate(90deg)",
      },
    },
    svg: {
      transition: "all .25s ease-in-out",
    },
    icon: {
      cursor: "pointer",
    },
    ul: {
      position: "absolute",
      top: "100%",
      right: "0%",
      // transform: 'translate(-50%)',
      opacity: 0,
      visibility: "hidden",
      zIndex: 100,
      margin: 0,
      padding: 0,

      li: {
        backgroundColor: "#fff",
        color: "#333",
        borderRadius: "25px",
        minHeight: 20,
        minWidth: 180,
        marginBottom: 5,
        fontFamily: theme.typography.fontFamily,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "20px",
        color: "#000000",
        textAlign: "center",
        padding: "0 15px",
        cursor: "pointer",
        transition: "all .25s ease-in",

        "&:hover": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
}));
const Td = styled("div")(({ theme, width }) => ({
  flex: width,
}));

export default function DTable(props) {
  const rand = Math.floor(Math.random() * 9999 + 1);
  return (
    <ThemeProvider theme={theme}>
      <Box
        className="props.datatable"
        paddingRight="15px"
        sx={{ overflow: { xs: "auto", sm: "hidden" } }}
      >
        <Table>
          <Head>
            <Tr>
              {props.data !== false &&
                props.data.head.map((item, index) => {
                  return item.hasDropDown ? (
                    // <Td width={item.width} key={(props.keyprop ? props.keyprop : '') + rand + index}>
                    //     <Select
                    //     labelId="filterSelect"
                    //     id="filterSelect"
                    //     onChange={handleChange}
                    //     >
                    //         <MenuItem value="">{item.label}</MenuItem>
                    //         {item.items.map((el, ind) => {
                    //             <MenuItem value={el}>{el}</MenuItem>
                    //         })}
                    //     </Select>
                    // </Td>
                    <Td
                      width={item.width}
                      key={(props.keyprop ? props.keyprop : "") + rand + index}
                    >
                      {item.label}
                    </Td>
                  ) : (
                    <Td
                      width={item.width}
                      key={(props.keyprop ? props.keyprop : "") + rand + index}
                    >
                      {item.label}
                    </Td>
                  );
                })}
            </Tr>
          </Head>
          <Body
            className={
              props.data !== false && props.data.isList ? "islist" : ""
            }
          >
            {props.data !== false &&
              props.data.body.slice(0, 100).map((row, index) => {
                return (
                  <Tr
                    className={"tr"}
                    key={
                      (props.keyprop ? props.keyprop : "") +
                      "tr-" +
                      rand +
                      "-" +
                      index
                    }
                  >
                    {row.icon && row.icon == "warning" && (
                      <WarningIcon
                        className="icontr"
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    )}
                    {row.icon && row.icon == "danger" && (
                      <ErrorIcon className="icontr" sx={{ color: "#e91e63" }} />
                    )}
                    {row.columns.map((col, ind) => {
                      return (
                        <Td
                          className="td"
                          width={
                            props.data?.head[ind]?.width
                              ? props.data?.head[ind]?.width / 100
                              : "auto"
                          }
                          key={
                            (props.keyprop ? props.keyprop + "td" : "td") +
                            rand +
                            ind
                          }
                        >
                          {col.icon && col.alignLeft && (
                            <Box
                              className="leftIcon"
                              color={
                                col.colorIcon
                                  ? col.colorIcon
                                  : theme.palette.primary.main
                              }
                            >
                              {col.icon}
                            </Box>
                          )}
                          {col.type
                            ? (() => {
                                switch (col.type) {
                                  case "link":
                                    return (
                                      <Box
                                        color={col.color ? col.color : "#fff"}
                                        component="span"
                                        className="link"
                                        onClick={col.onClick}
                                      >
                                        {col.text}
                                      </Box>
                                    );
                                  case "text":
                                    return (
                                      <Box
                                        color={col.color ? col.color : "#fff"}
                                        component="span"
                                        className="text"
                                        textAlign={"center"}
                                      >
                                        {col.text}
                                      </Box>
                                    );
                                  case "more":
                                    return (
                                      <Box
                                        color={col.color ? col.color : "#fff"}
                                        component="span"
                                        className="more"
                                        onClick={col.onClick}
                                      >
                                        {col.text}
                                      </Box>
                                    );
                                  case "updelete":
                                    return (
                                      <Box
                                        width={"100%"}
                                        color={col.color ? col.color : "#fff"}
                                        component="span"
                                        className="text"
                                        display={"flex"}
                                        justifyContent="space-between"
                                      >
                                        {col.deleteText && (
                                          <Box
                                            component={"span"}
                                            className="updelete up"
                                          >
                                            <DeleteOutlineIcon
                                              style={{
                                                position: "relative",
                                                top: 3,
                                              }}
                                            />{" "}
                                            <Box
                                              verticalAlign="text-bottom"
                                              component={"span"}
                                            >
                                              {col.deleteText}
                                            </Box>
                                          </Box>
                                        )}
                                        {col.updateText && (
                                          <Box
                                            component={"span"}
                                            className="updelete de"
                                          >
                                            <BorderColorIcon /> {col.updateText}
                                          </Box>
                                        )}
                                      </Box>
                                    );
                                  case "status":
                                    return (
                                      <Box
                                        color={col.color ? col.color : "#fff"}
                                        component="span"
                                        className="status"
                                      >
                                        {col.text}
                                      </Box>
                                    );
                                  case "menu":
                                    return (
                                      <Box className="menudots">
                                        <Box className="icon">
                                          <MoreVertIcon />
                                        </Box>
                                        <Box component={"ul"}>
                                          {col.items &&
                                            col.items.map((el, ii) => {
                                              return (
                                                <Box
                                                  key={
                                                    ii + "menu" + index + ind
                                                  }
                                                  onClick={
                                                    el.callback
                                                      ? el.callback
                                                      : () => {}
                                                  }
                                                  component={"li"}
                                                >
                                                  {el.label}
                                                </Box>
                                              );
                                            })}
                                        </Box>
                                      </Box>
                                    );
                                  case "rightButton":
                                    return col.text ? (
                                      <Box
                                        color={col.color ? col.color : "#fff"}
                                        className="rightButton"
                                        onClick={col.onClick}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          textTransform: "none",
                                          borderColor: col.color
                                            ? col.color + "!important"
                                            : "#fff!important",
                                        }}
                                      >
                                        {props.loading &&
                                        props.elem === col.indx ? (
                                          <CircularProgress size="1rem" />
                                        ) : (
                                          col.text
                                        )}
                                      </Box>
                                    ) : (
                                      <></>
                                    );
                                  default:
                                    return (
                                      <Box
                                        color={col.color ? col.color : "#fff"}
                                        component="span"
                                        className="text"
                                      >
                                        {col.text}
                                      </Box>
                                    );
                                }
                              })()
                            : col.text}
                          {col.icon && !col.alignLeft && (
                            <Box
                              className="rightIcon"
                              color={
                                col.colorIcon
                                  ? col.colorIcon
                                  : theme.palette.primary.main
                              }
                            >
                              {col.icon}
                            </Box>
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Body>
        </Table>
        {props.load && (
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ position: { xs: "absolute", sm: "relative" } }}
          >
            <CircularProgress />
          </Box>
        )}
        {props.showPagination && (
          <ShowMore
            onClick={() => {
              props.setPage(parseInt(props.page) + 1);
            }}
          >
            afficher plus{" "}
          </ShowMore>
        )}
        {/*  {props.data.body.length > pg * limit && (
          <ShowMore
            onClick={() => {
              setPg(parseInt(pg) + 1);
              console.log(props.data);
            }}
          >
            afficher plus{" "}
          </ShowMore>
        )} */}
      </Box>
    </ThemeProvider>
  );
}
