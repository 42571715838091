import { Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DTable from "../../../components/DTable";
import filterIcon from "../../../assets/images/sliders.png";
import { Image, TextInput } from "react-native-web";
import classNames from "classnames";
import AddPartner from "./New";
import EditPartner from "./Edit";
import RemovePartner from "./Remove";
import Profile from "./Profile";

// Import tools
import Search from "../Tools/Search";
import { HeaderTab, TabItems, TabItem, Filter } from "../Tools/Tab";
import Title from "../Tools/Title";
import UserFigure from "../Tools/UserFigure";
import { Add } from "@mui/icons-material";
import partnersService from "../../../store/apis/partnersApis";
import Congrats from "../congrats/Congrats";
import RemoveCongrat from "../congrats/RemoveCongrats";
import EditCongrat from "../congrats/EditCongrat";
import { useSelector } from "react-redux";
import { limitPagination } from "../../../config/keys";

const HEAD = [
  {
    label: false,
    width: 60,
  },
  {
    label: false,
    width: 30,
  },
  {
    label: false,
    width: 10,
  },
];

export default function Partners() {
  const { user } = useSelector((state) => state.userAuth);
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const [dialog, setDialog] = useState(false);
  const [activePage, setActivePage] = useState("list");
  const [currentProfile, setCurrentProfile] = useState(false);
  const [allPartners, setAllPartners] = useState(false);
  const [addedRecently, setAddedRecently] = useState(false);
  const [suspended, setSuspended] = useState(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editedProfil, setEditedProfil] = useState({});
  const [remove, setRemove] = useState(false);
  const [congrat, setCongrat] = useState(false);
  const [removeCongrat, setRemoveCongrat] = useState(false);
  const [editCongrat, setEditCongrat] = useState(false);
  const [load, setLoad] = useState(true);
  const [id, setId] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const [page, setPage] = useState(1);
  const [showPagination, setShowPagination] = useState(false);

  const handleShowProfile = (profil) => {
    setCurrentProfile(profil);
    setActivePage("detail");
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    setAllPartners(false);
    setPage(1);
    fetchData();
  }, [searchValue]);

  const fetchData = () => {
    setShowPagination(false);
    setLoad(true);
    let AllPartners = {
      isList: true,
      head: HEAD,
      body: [],
    };
    partnersService
      .listPartners(page, searchValue)
      .then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            const El = res[i];
            if (
              user?.data?.role === "ROLE_SUPER_ADMIN" ||
              user?.user?.data?.role === "ROLE_SUPER_ADMIN"
            ) {
              var row = [
                {
                  type: false,
                  text: (
                    <UserFigure
                      name={El.prenom + " " + El.nom}
                      Address={El.adresse}
                      image={""}
                    />
                  ),
                  link: "",
                },
                {
                  type: "more",
                  text: "Voir profil",
                  onClick: () => handleShowProfile(El),
                },
                {
                  type: "menu",
                  text: "",
                  items: [
                    {
                      label: El.telephone,
                      callback: false,
                    },
                    {
                      label: El.email,
                      callback: false,
                    },
                    {
                      label: El.status === 1 ? "Désactiver" : "Activer",
                      callback: () => {
                        setEdit(true);
                        setEditedProfil(El);
                      },
                    },
                    {
                      label: "Modifier",
                      callback: () => {
                        setEdit(true);
                        setEditedProfil(El);
                      },
                    },
                    {
                      label: "Supprimer",
                      callback: () => {
                        setId(El.id);
                        setRemove(true);
                      },
                    },
                  ],
                },
              ];
            } else {
              var row = [
                {
                  type: false,
                  text: (
                    <UserFigure
                      name={El.prenom + " " + El.nom}
                      Address={El.adresse}
                      image={""}
                    />
                  ),
                  link: "",
                },
                {
                  type: "more",
                  text: "Voir profil",
                  onClick: () => handleShowProfile(El),
                },
                {
                  type: "menu",
                  text: "",
                  items: [
                    {
                      label: El.telephone,
                      callback: false,
                    },
                    {
                      label: El.email,
                      callback: false,
                    },
                    {
                      label: El.status === 1 ? "Désactiver" : "Activer",
                      callback: () => {
                        setEdit(true);
                        setEditedProfil(El);
                      },
                    },
                    {
                      label: "Modifier",
                      callback: () => {
                        setEdit(true);
                        setEditedProfil(El);
                      },
                    },
                  ],
                },
              ];
            }

            page > 1
              ? allPartners.body.push({ columns: row })
              : AllPartners.body.push({ columns: row });
          }

          page > 1 ? setAllPartners(allPartners) : setAllPartners(AllPartners);

          setTimeout(() => {
            setLoad(false);
            res.length === limitPagination
              ? setShowPagination(true)
              : setShowPagination(false);
          }, 500);
        }
      })
      .catch((e) => {
        setLoad(false);
        console.log(error);
      });
  };

  const handleDebouncedChange = useCallback(
    _.debounce((newValue) => {
      //console.log("User finished typing:", newValue);
      setSearchValue(newValue);
      // Handle the finish typing event here
    }, 500),
    [] // Empty array ensures the debounce function is only created once
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    handleDebouncedChange(newValue);
  };

  return (
    <Box>
      {(() => {
        switch (activePage) {
          case "detail":
            return (
              <Profile
                goBack={() => setActivePage("list")}
                currentProfile={currentProfile}
                setEdit={() => {
                  setEdit(true);
                  setEditedProfil(currentProfile);
                }}
              />
            );

          case "list":
            return (
              <>
                <HeaderTab className="header-tab">
                  <Filter>
                    <TabItems>
                      <TabItem
                        className={classNames({ hovered: currentTab == 1 })}
                        onClick={() => setCurrentTab(1)}
                      >
                        Tous les partenaires
                      </TabItem>
                      <TabItem
                        className={classNames({ hovered: currentTab == 2 })}
                        onClick={() => setCurrentTab(2)}
                      >
                        Ajouts récents
                      </TabItem>
                      <TabItem
                        className={classNames({ hovered: currentTab == 3 })}
                        onClick={() => setCurrentTab(3)}
                      >
                        Partenaires suspendus
                      </TabItem>
                    </TabItems>

                    <Search className={"searchinput"}>
                      <TextInput
                        type="search"
                        placeholder="Recherche"
                        onChange={handleChange}
                      />
                    </Search>
                    {/* <Search className={"searchinput"}>
                      <TextInput type="search" placeholder="Recherche" />
                    </Search>
                    <Box className="filterIcon">
                      <Image
                        source={filterIcon}
                        style={{ width: 30, height: 30 }}
                      />
                    </Box> */}
                  </Filter>
                </HeaderTab>
                <Title
                  onClick={() => setAdd(true)}
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  <Add sx={{ fontSize: 34, verticalAlign: "bottom" }} /> ajouter
                  un partenaire
                </Title>
                {currentTab == 1 && (
                  <DTable
                    data={allPartners}
                    keyprop="allPartners"
                    load={load}
                    showPagination={showPagination}
                    setPage={setPage}
                    page={page}
                  />
                )}
                {currentTab == 2 && (
                  <DTable
                    data={addedRecently}
                    keyprop="addedRecentlypartners"
                    load={load}
                    showPagination={showPagination}
                    setPage={setPage}
                    page={page}
                  />
                )}
                {currentTab == 3 && (
                  <DTable
                    data={suspended}
                    keyprop="suspendedpartners"
                    load={load}
                    showPagination={showPagination}
                    setPage={setPage}
                    page={page}
                  />
                )}
              </>
            );
        }
      })()}

      <AddPartner
        dialog={add}
        onClose={() => setAdd(false)}
        setCongrat={setCongrat}
      />
      {edit && (
        <EditPartner
          dialog={edit}
          editedProfil={editedProfil}
          setCurrentProfile={setCurrentProfile}
          setEditCongrat={setEditCongrat}
          onClose={() => {
            setEdit(false);
            setEditedProfil({});
          }}
        />
      )}

      {remove && (
        <RemovePartner
          remove={remove}
          id={currentProfile || id}
          onClose={() => setRemove(false)}
          setRemoveCongrat={setRemoveCongrat}
        />
      )}
      {congrat && (
        <Congrats
          congrat={congrat}
          onClose={() => {
            setCongrat(false);
            fetchData();
          }}
          type={"partenaire"}
        />
      )}
      {removeCongrat && (
        <RemoveCongrat
          removeCongrat={removeCongrat}
          onClose={() => {
            setRemoveCongrat(false);
            fetchData();
          }}
        />
      )}
      {editCongrat && (
        <EditCongrat
          editCongrat={editCongrat}
          onClose={() => {
            setEditCongrat(false);
            fetchData();
          }}
        />
      )}
    </Box>
  );
}
