import { forwardRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import signinBg from "../../../../assets/images/signin_bg.png";
import { styled } from "@mui/system";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useTheme } from "@emotion/react";
import { RegisterHeading } from "../../../../components/headings";
import { EmailInput, PasswordInput } from "../../../../components/inputs";
import { userLogin } from "../../../../store/slices/userAuthReducer";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigation } from "@react-navigation/native";

const schema = yup.object({
  email: yup
    .string()
    .email("Entrer un email valide")
    .required("L'email est requis"),
  password: yup.string().required("Le mot de passe est requis"),
});

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: "100%",
  backgroundColor: "#fff",
  backgroundImage: `url(${signinBg})`,
  backgroundSize: "100%",
  backgroundPosition: "bottom center",
  padding: theme.spacing(4, 1),
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.up("md")]: {
    backgroundImage: "none",
    padding: theme.spacing(2, 0),
  },
}));

export default function Login({ setPage, handleDialog }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const { isFetching, error } = useSelector((state) => state.userAuth);
  const [visible, setVisible] = useState(false);
  const [err, setErr] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const navigation = useNavigation();

  const onSubmit = (data) => {
    const { email, password } = data;
    //console.log(data);

    const res = dispatch(userLogin({ email, password }));
    var test = 0;
    res
      .then((r) => {
        //console.log("rrrr======>>", r?.payload?.user)
        setErr("");

        if (
          r?.payload?.user?.code == 200 &&
          r?.payload?.user?.data?.role == "ROLE_CLIENT"
        ) {
          navigation.navigate("Dashboard");
          test = 1;
          handleDialog()
        } else if (
          r?.payload?.user?.code == 200 &&
          (r?.payload?.user?.data?.role == "ROLE_ADMIN" ||
            r?.payload?.user?.data?.role == "ROLE_SUPER_ADMIN")
        ) {
          navigation.navigate("AdminDashboard");
          test = 2;
          handleDialog()
        } else if (
          r?.payload?.user?.code == 200 &&
          r?.payload?.user?.data?.role == "ROLE_ACTIVITE"
        ) {
          navigation.navigate("ActivityDashboard");
          test = 2;
          handleDialog()
        } else if (
          r?.payload?.user?.code == 200 &&
          r?.payload?.user?.data?.role == "ROLE_FACTURATION"
        ) {
          navigation.navigate("FacturationDashboard");
          test = 2;
          handleDialog()
        } else if (
          r?.payload?.user?.code == 200 &&
          r?.payload?.user?.data?.role == "ROLE_PRESTATAIRE"
        ) {
          navigation.navigate("PartnerDashboard");
          test = 2;
          handleDialog()
        } else {
          setErr("Une erreur est survenue veuillez réessayez plus tard.");
        }

        //console.log("test00000======>>", res)
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <BoxContainer>
        <Container maxWidth={isMd ? "sm" : "xs"}>
          <Box display={"flex"} flexDirection={{ xs: "column" }}>
            <Box component={"span"} alignSelf={{ xs: "center", md: "end" }}>
              <IconButton
                aria-label="close"
                size="large"
                onClick={handleDialog}
                sx={{
                  "& .closeIcon": {
                    fontSize: "30px",
                    color: "black",
                    transition: "all 0.5s ease-in-out",
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.closeButton,
                    "& .closeIcon": {
                      transform: "rotate(-90deg)",
                    },
                  },
                }}
              >
                <CloseRounded fontSize="large" className="closeIcon" />
              </IconButton>
            </Box>
            <Box mb={2} mt={{ md: -5 }}>
              <RegisterHeading>authentification</RegisterHeading>
            </Box>
          </Box>
          <Box paddingLeft={{ md: 5 }} paddingRight={{ md: 5 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display={"flex"} flexDirection={"column"} gap={2} mb={0.5}>
                <Box>
                  <EmailInput register={{ ...register("email") }} />
                  {errors.email && (
                    <Typography
                      fontSize={{ xs: 14 }}
                      lineHeight={{ xs: "20px" }}
                      color="red"
                    >
                      {errors.email.message}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <PasswordInput
                    visible={visible}
                    handleVisible={() => setVisible(!visible)}
                    register={{ ...register("password") }}
                  />
                  {errors.password && (
                    <Typography
                      fontSize={{ xs: 14 }}
                      lineHeight={{ xs: "20px" }}
                      color="red"
                    >
                      {errors.password.message}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                component={"div"}
                display={"flex"}
                alignItems={"start"}
                justifyContent={"end"}
              >
                <Typography
                  variant={"body2"}
                  textTransform={"uppercase"}
                  component={"a"}
                  fontSize={{ xs: 14, md: 16 }}
                  onClick={() => setPage("Forgot_Password")}
                  sx={[
                    {
                      cursor: "pointer",
                      position: "relative",
                      "&::after": {
                        content: `""`,
                        width: "100%",
                        height: "1px",
                        backgroundColor: "transparent",
                        position: "absolute",
                        bottom: -10,
                        left: 0,
                        //display: "none",
                        transition: "all .5s ease-in-out",
                      },
                      "&:hover": {
                        "&::after": {
                          bottom: 0,
                          backgroundColor: "#000000",
                        },
                      },
                    },
                  ]}
                >
                  Mot de passe oublié
                </Typography>
              </Box>

              {error && (
                <Typography fontSize={{ xs: 18 }} color="red">
                  {error}
                </Typography>
              )}
              {err && (
                <Typography fontSize={{ xs: 18 }} color="red">
                  {err}
                </Typography>
              )}

              <Box
                display={"flex"}
                mb={{ xs: 4, md: 6 }}
                mt={4}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {isFetching ? (
                  <CircularProgress />
                ) : (
                  <CustomButton type="submit">Se connecter</CustomButton>
                )}
              </Box>
            </form>
            <Typography
              variant={"body2"}
              textTransform={"uppercase"}
              fontWeight={400}
              fontSize={{ xs: 16 }}
              textAlign={"center"}
              mb={3}
            >
              Vous n’avez pas encore de compte ?{" "}
              <Typography
                variant={"body2"}
                component={"a"}
                color={"primary"}
                fontWeight={700}
                fontSize={{ xs: 16 }}
                /*sx={{ cursor: "pointer" }}*/
                onClick={() => {
                  handleDialog();
                  navigation.navigate("Register");
                }}
                sx={[
                  {
                    cursor: "pointer",
                    position: "relative",
                    "&::after": {
                      content: `""`,
                      width: "100%",
                      height: "1px",
                      backgroundColor: "transparent",
                      position: "absolute",
                      bottom: -10,
                      left: 0,
                      //display: "none",
                      transition: "all .5s ease-in-out",
                    },
                    "&:hover": {
                      "&::after": {
                        bottom: 0,
                        backgroundColor: theme.palette.primary.main,
                      },
                    },
                  },
                ]}
              >
                Incrivez-vous !
              </Typography>
            </Typography>
          </Box>
        </Container>
      </BoxContainer>
    </>
  );
}
