import axios from "axios";
import authHeader from "./authHeader";
import { base_url_api } from "../../config/keys";

const listAdmins = async (page, searchValue = "") => {
  try {
    const response = await axios.get(
      `${base_url_api}/users?page=${page}&search=${searchValue}&roles[]=ROLE_ADMIN&roles[]=ROLE_FACTURATION&roles[]=ROLE_ACTIVITE&order[id]=desc`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};
const listActivities = async () => {
  try {
    const response = await axios.get(
      `${base_url_api}/users?roles[]=ROLE_ADMIN&roles[]=ROLE_FACTURATION&roles[]=ROLE_ACTIVITE`,
      {
        headers: authHeader(),
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const addAdmin = async (data, withToken = false) => {
  try {
    const response = await axios.post(`${base_url_api}/users`, data, {
      headers: withToken ? authHeader() : null,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const editAdmin = async (id, data) => {
  try {
    const response = await axios.put(
      `${base_url_api}/users/${id}`,
      {
        ...data,
      },
      { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

const removeAdmin = async (id) => {
  try {
    const response = await axios.delete(`${base_url_api}/users/${id}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

const detailAdmin = async (id) => {
  try {
    const response = await axios.get(`${base_url_api}/users/${id}`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

const adminsService = {
  addAdmin,
  editAdmin,
  removeAdmin,
  detailAdmin,
  listAdmins,
  listActivities,
};

export default adminsService;
